import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Grid, Button, Alert, Stack } from "@mui/material";
import { Select, DateTimePicker, makeValidate, makeRequired } from "mui-rff";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import MenuItem from "@mui/material/MenuItem";
import * as Yup from "yup";
import { Form } from "react-final-form";
import { TYPE_OF_TEST } from "../../appConstants";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { DATABASE_URL } from "../../appConstants";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

function UpdateGroup() {
  const { user } = useAuthContext();
  const params = useParams();
  const navigate = useNavigate();
  const [group, setGroup] = useState();
  const [candidateList, setCandidateList] = useState([]);
  const [unSelectedCandidateList, setUnSelectedCandidateList] = useState([]);
  const [selectedCandidateList, setSelectedCandidateList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const { isLoading, startLoading, stopLoading } = useLoadingContext();

  const [message, setMessage] = useState();
  const [errMessage, setErrMessage] = useState();

  const schema = Yup.object().shape({
    test_date: Yup.date("invalid date")
      .min(new Date(), "Test date must be later than today")
      .required("Test date is required"),
    type_of_test_id: Yup.string().required("Please select type of the test"),
  });

  const validate = makeValidate(schema);

  const required = makeRequired(schema);

  const formFields = [
    <Select
      label="Type of Test"
      name="type_of_test_id"
      placeholder="Type of Test"
      required={required.type_of_test_id}
    >
      {TYPE_OF_TEST.map((o, idx) => (
        <MenuItem value={idx} key={idx}>
          {o}
        </MenuItem>
      ))}
    </Select>,
    <DateTimePicker
      label="Test Date"
      name="test_date"
      required={required.test_date}
      dateFunsUtils={DateFnsUtils}
    />,
  ];

  const columns = [
    { field: "first_name", headerName: "First Name", width: 200 },
    { field: "last_name", headerName: "Last Name", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "government_id", headerName: "Government ID", width: 200 },
  ];

  useEffect(() => {
    Axios.get(DATABASE_URL + "/group/" + user.test_center +"/group_id/"+ params.id, {
      headers: AuthHeader(),
    }).then((response) => {
      setGroup(...response.data.groups);
    });
    Axios.get(DATABASE_URL + "/candidate/group/" + params.id, {
      headers: AuthHeader(),
    }).then((response) => {
      setSelectedCandidateList(response.data.candidate);
      setUnSelectedCandidateList(response.data.candidate.map((i) => i.id));
    });
  }, [params.id]);

  const handlePageChange = (params) => {
    const newPage = params >= page ? page + 1 : params < page ? page - 1 : page;
    setPage(newPage);
  }

  useEffect(() => {
    startLoading();

    if (group && group.id) {
      Axios.get(`${DATABASE_URL}/candidate/test_center/${user.test_center}?page=${page}&pageSize=${pageSize}`, {
        headers: AuthHeader(),
      }).then((response) => {
        let filteredList = response.data.responseData.data.filter(
          (i) => i.group_id === "0" || i.group_id === group.id
        );
        setCandidateList(filteredList);
        setTotalRows(response.data.count);
        stopLoading();
      }).catch((error) => {
        console.log('Error: ', error);
        stopLoading();
      });
    }
  }, [group, page, pageSize]);

  const onSubmit = async (values) => {
    startLoading();
    setMessage("");
    setErrMessage("");
    values.test_date = format(new Date(values.test_date), "yyyy-MM-dd H:mm");
    const candidateIds = selectedCandidateList.map((a) => a.id);
    const body = {
      ...values,
      name: TYPE_OF_TEST[values.type_of_test_id] + " " + values.test_date,
      candidates: candidateIds,
    };

    Axios.put(DATABASE_URL + "/group/" + group.id, body, {
      headers: AuthHeader(),
    })
      .then((response) => {
        setMessage("Group Updated!");
        console.log(response.data);
        navigate("/groups/manage-groups");
        stopLoading();
      })
      .catch((error) => {
        setErrMessage("Something wrong!");
        // console.log(error.response.data.message);
        stopLoading();
      });
  };

  function getFullName(params) {
    return `${params.row.first_name || ""} ${params.row.last_name || ""}`;
  }

  return (
    <div>
      <Stack spacing={2}>
        <h1>Update Group </h1>
        {isLoading ? (
          <div className="loader-display">
            <Loader />
          </div>
        ) : (
          <>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={group}
              render={({
                hasSubmitErrors,
                handleSubmit,
                submitting,
                form,
                values,
              }) => (
                <form onSubmit={handleSubmit} className="add-user-form">
                  <Grid container direction="row" spacing={4}>
                    {formFields.map((field, index) => (
                      <Grid item xs={6} key={index}>
                        {field}
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container spacing={4} style={{ marginTop: "16px" }}>
                    <Grid item xs={4}>
                      <div style={{ height: 600, width: "100%" }}>
                        <DataGrid
                          pageSize={pageSize}
                          onPageSizeChange={(newPageSize) =>
                            setPageSize(newPageSize)
                          }
                          rowsPerPageOptions={[10, 25, 50]}
                          pagination
                          rows={selectedCandidateList}
                          disableSelectionOnClick
                          columns={[
                            {
                              field: "full_name",
                              headerName: "Full Name",
                              flex: 1,
                              valueGetter: getFullName,
                            },
                            {
                              field: "actions",
                              type: "actions",
                              width: 50,
                              sortable: false,
                              filterable: false,
                              getActions: (params) => [
                                <GridActionsCellItem
                                  icon={<DeleteForeverIcon />}
                                  label="Delete"
                                  onClick={() => {
                                    const newList = selectedCandidateList.filter(
                                      (c) => c.id !== params.id
                                    );
                                    setSelectedCandidateList(newList);
                                    setUnSelectedCandidateList(
                                      unSelectedCandidateList.filter(
                                        (c) => c !== params.id
                                      )
                                    );
                                  }}
                                />,
                              ],
                            },
                          ]}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div style={{ height: 600, width: "100%" }}>
                        <DataGrid
                          pageSize={pageSize}
                          page={(page - 1)}
                          onPageSizeChange={(newPageSize) =>
                            setPageSize(newPageSize)
                          }
                          onPageChange={(params) => handlePageChange(params)}
                          rowsPerPageOptions={[10, 25, 50]}
                          pagination
                          onSelectionModelChange={(newSelectionModel) => {
                            const uniqueSelectedIds = [...new Set([...unSelectedCandidateList, ...newSelectionModel])];
                            let newSelectedList = [...selectedCandidateList, ...candidateList.filter((c) => uniqueSelectedIds.includes(c.id))];
                            
                            const uniqueListOfCandidates = newSelectedList.reduce((acc, current) => {
                              if (!acc.some(obj => obj.id === current.id)) {
                                acc.push(current);
                              }
                              return acc
                            }, []);
                            
                            setSelectedCandidateList(uniqueListOfCandidates);
                            setUnSelectedCandidateList(uniqueSelectedIds);
                          }}
                          selectionModel={unSelectedCandidateList}
                          checkboxSelection
                          rows={candidateList}
                          columns={columns}
                          rowCount={totalRows}
                          paginationMode="server"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} className="form-button-group">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        disabled={submitting}
                      >
                        UPDATE
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        type="submit"
                        size="large"
                        disabled={submitting}
                      >
                        CANCEL
                      </Button>
                    </Grid>
                  </Grid>
                  {hasSubmitErrors && <Alert severity="error">error</Alert>}
                  {/* <pre>{JSON.stringify(values)}</pre> */}
                </form>
              )}
            />
            {message && <Alert severity="success">{message}</Alert>}
            {errMessage && <Alert severity="warning">{errMessage}</Alert>}
          </>
        )}
      </Stack>
    </div>
  );
}

export default UpdateGroup;
