import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import AuthHeader from "../../services/AuthHeader";
import { DATABASE_URL } from "../../appConstants";
import { UserForm } from "../../components/forms";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

function UpdateUser() {
  const params = useParams();
  const [userDetails, setUserDetails] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const { isLoading, startLoading, stopLoading } = useLoadingContext();

  useEffect(() => {
    startLoading();

    Axios.get(DATABASE_URL + "/user/" + params.id, {
      headers: AuthHeader(),
    }).then((response) => {
      const user = response.data.user;
      setUserDetails(...user);
      stopLoading();
    }).catch((error) => {
      console.log("Error: ", error);
      stopLoading();
    });
  }, [params.id]);

  const enableEdit = () => {
    setIsDisabled(false);
  };

  return (
    <>
      <div className="title-with-button">
        <h1>
          Edit {userDetails.first_name} {userDetails.last_name}
        </h1>
        <Button
          variant="outlined"
          size="large"
          startIcon={<EditIcon />}
          onClick={enableEdit}
        >
          Enable Edit
        </Button>
      </div>
      {isLoading ? (
        <div className="loader-display">
          <Loader />
        </div>
      ) : (
        <UserForm
          initialValues={userDetails}
          apiURL={DATABASE_URL + "/user/" + userDetails.id}
          submitNav={"/user/manage-user"}
          cancelNav={"/user/manage-user"}
          isDisabled={isDisabled}
          isLoading={isLoading}
          startLoading={startLoading}
          stopLoading={stopLoading}
        />
      )}
    </>
  );
}

export default UpdateUser;
