import Axios from 'axios';
import { DATABASE_URL } from '../appConstants';
import AuthHeader from './AuthHeader';

export const getExaminers = async (testCenter) => {
  try {
    const response = await Axios.get(`${DATABASE_URL}/user/get_examiners/${testCenter}`, {
      headers: AuthHeader(),
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateExaminers = async (candidateId, data) => {
  try {
    const response = await Axios.put(
      `${DATABASE_URL}/candidate/update_examiners/${candidateId}`,
      data,
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};