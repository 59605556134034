import React, { useState, useEffect } from "react";
import { SecondaryNavbar } from "../../components/common";
import { NavLink } from "react-router-dom";
import { Grid } from "@mui/material";
import { DATABASE_URL } from "../../appConstants";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import { useAuthContext } from "../../hooks/useAuthContext";
import * as FaIcons from "react-icons/fa";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

import "./Licenses.css";

const subMenu = [<NavLink to="/"> <FaIcons.FaCertificate className="fa-i" /> Licenses</NavLink>];

function Licenses() {
  const { user } = useAuthContext();
  const [testCenterDetails, setTestCenterDetails] = useState([]);
  const { isLoading, startLoading, stopLoading } = useLoadingContext();

  useEffect(() => {
    startLoading();

    Axios.get(DATABASE_URL + "/test_center/" + user.test_center, {
      headers: AuthHeader(),
    }).then((response) => {
      const details = response.data.testCenter;
      setTestCenterDetails(...details);
      stopLoading();
    }).catch((error) => {
      console.log('Error: ', error);
      stopLoading();
    });
  }, []);

  return (
    <div className="page-content-container">
      <SecondaryNavbar menu={subMenu} />
      <div className="page-content">
        <h1>Licenses</h1>
        {isLoading ? (
          <div className="loader-display">
            <Loader />
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="licenses-card">
                <h2>Full Test (Productive and Receptive Skills)</h2>
                <p>{testCenterDetails.full_test_license} Licenses Available</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="licenses-card">
                <h2>Productive Skills Test</h2>
                <p>
                  {testCenterDetails.productive_skill_test_license} Licenses
                  Available
                </p>
              </div>
            </Grid>
          
            <Grid item xs={6}>
              <div className="licenses-card">
                <h2>Receptive Skills Test</h2>
                <p>
                  {testCenterDetails.receptive_skill_test_license} Licenses
                  Available
                </p>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default Licenses;
