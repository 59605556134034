import React from "react";
import { SecondaryNavbar } from "../../components/common";
import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import * as FaIcons from 'react-icons/fa'

import "./Questions.css";

const subMenu = [
  // <NavLink to="add-question-group"> <FaIcons.FaRegObjectGroup className="m-r" />  Add Question Group</NavLink>,
  <NavLink to="manage-question-group"> <FaIcons.FaCog className="m-r" style={{width: 20}} />  Manage Question Number</NavLink>,
  <NavLink to="add-questions"> <FaIcons.FaPlusCircle className="m-r" />  Add Questions</NavLink>,
  <NavLink to="speaking-questions"> <FaIcons.FaMicrophoneAlt className="m-r" />  Speaking Questions</NavLink>,
  <NavLink to="writing-questions"> <FaIcons.FaEdit className="m-r" /> Writing Questions</NavLink>,
];

function Questions() {
  return (
    <div className="page-content-container">
      <SecondaryNavbar menu={subMenu} />
      <div className="page-content">
        <Outlet />
      </div>
    </div>
  );
}

export default Questions;
