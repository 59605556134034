import React, { useEffect, useState } from "react";
import { Grid, Button, MenuItem } from "@mui/material";
import { USER_ROLES, DATABASE_URL } from "../../appConstants";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { useAuthContext } from "../../hooks/useAuthContext";
import Axios from "axios";
import AuthHeader from "../../services/AuthHeader";
import * as XLSX from "xlsx/xlsx.js";
import { Select } from "mui-rff";
import { Form } from "react-final-form";
import { useCEFRconverter } from "../../hooks/useCEFRconverter";
import { useCEFRalert } from "../../hooks/useCEFRalert";

import "react-datepicker/dist/react-datepicker.css";

const reports = [
  {
    name: "Users",
    api: "/users/",
    dateRange: false,
    availableFormat: ["xls"],
    role: [USER_ROLES.Administrator],
  },
  {
    name: "Licenses",
    api: "/licenses/",
    dateRange: false,
    availableFormat: ["xls"],
    role: [USER_ROLES.Administrator],
  },
  {
    name: "Examiner History",
    api: "/examiner/",
    dateRange: true,
    availableFormat: ["xls"],
    role: [USER_ROLES.Administrator, USER_ROLES.Examiner],
  },
  {
    name: "Candidates",
    api: "/candidates/",
    dateRange: true,
    availableFormat: ["xls"],
    role: [USER_ROLES.Administrator, USER_ROLES.Proctor],
  },
  {
    name: "Candidate Results",
    api: "/grading/",
    dateRange: false,
    availableFormat: ["xls"],
    selectTestCenter: true,
    role: [USER_ROLES.Administrator, USER_ROLES.Proctor, USER_ROLES.Superuser],
  },
  {
    name: "Security Flags",
    api: "/flag/",
    dateRange: true,
    availableFormat: ["xls"],
    role: [USER_ROLES.Administrator, USER_ROLES.Proctor, USER_ROLES.Examiner],
  },
  {
    name: "Test Center",
    api: "/test_center/",
    dateRange: false,
    selectTestCenter: true,
    availableFormat: ["xls"],
    role: [USER_ROLES.Superuser],
  },
  {
    name: "License History",
    api: "/license_history/",
    dateRange: false,
    selectTestCenter: true,
    availableFormat: ["xls"],
    role: [USER_ROLES.Superuser],
  },
  {
    name: "Reading and Listening Report",
    api: "/reading_and_listening/",
    dateRange: false,
    availableFormat: ["xls"],
    role: [USER_ROLES.Superuser],
  },
];

const formats = [
  {
    type: "xls",
    imageURL: "/images/excel-logo.png",
  },
];

function DownloadReport() {
  const { user } = useAuthContext();
  const [reportList, setReportList] = useState([]);
  const [currentSelection, setCurrentSelection] = useState({
    name: "",
    fromDate: format(new Date(), "yyyy-MM-dd"),
    toDate: format(new Date(), "yyyy-MM-dd"),
    format: "xls",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [testCenters, setTestCenters] = useState([])
  const [selectedTestCenter, setSelectedTestCenter] = useState('')
  const [selectTestCenterError, setSelectTestCenterError] = useState(false)
  
  const handleDownload = () => {
    setIsLoading(true);
    let test_center = ''
    if (user.role === USER_ROLES.Superuser && currentSelection.name != 'Reading and Listening Report') {
      if (selectedTestCenter === '') {
        setSelectTestCenterError(true)
      }else{
        test_center = selectedTestCenter
      }
    }else{
      test_center = user.test_center
    }

    if (test_center) {
      Axios.post(
        DATABASE_URL + "/report" + currentSelection.api + test_center,
        { ...currentSelection },
        { headers: AuthHeader() }
      ).then((response) => {
        setIsLoading(false);
        let worksheet;
        if (response.data.count === 0) {
          worksheet = XLSX.utils.json_to_sheet([{ message: "No data found" }]);
        } else {
          if (currentSelection.name === 'Candidate Results') {
            const response_data = response.data.report;

            response_data.forEach(item => {
              if (item.overall_score !== null) {
                const speakingBand = useCEFRconverter(item.speaking);
                const writingBand = useCEFRconverter(item.writing);

                if (item.type_of_test === 'Full Test') {
                  const listeningAndReadingBand = useCEFRconverter(item.reading_and_listening);
                  const alert1 = useCEFRalert(listeningAndReadingBand, speakingBand);
                  const alert2 = useCEFRalert(speakingBand, writingBand);
                  
                  if (user.role === USER_ROLES.Superuser) 
                    item.alert = alert1.status === 'warning' ? alert1.message : alert2.status === 'warning' ? alert2.message : alert1.message;
                } else if (item.type_of_test === 'Productive Skills Test') {
                  const alert = useCEFRalert(speakingBand, writingBand);

                  if (user.role === USER_ROLES.Superuser)
                    item.alert = alert.message;
                }
              } else {
                if (user.role === USER_ROLES.Superuser)
                  item.alert = null;
              }
            });

            worksheet = XLSX.utils.json_to_sheet(response_data);
          }else{
            worksheet = XLSX.utils.json_to_sheet(response.data.report);
          }
        }
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, currentSelection.name + ".xlsx");
      }); 
    }
  };

  const onChange = (e) => {
    let id  = e.target.value
    setSelectedTestCenter(id)
    setIsLoading(false)
  }
  
  useEffect(() => {
    Axios.get(
      DATABASE_URL + "/test_center",
      { headers: AuthHeader() }
    )
      .then((response) => {
        setTestCenters(response.data.testCenter)
        console.log(response.data.testCenter);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, [])

  useEffect(() => {
    setReportList(reports.filter((item) => item.role.includes(user.role)));
  }, []);

  return (
    <>
      <h1>Reports</h1>
      <Grid container direction="column">
        <ol>
          <li>
            <h5>Choose the report you wish to download:</h5>
            <Grid container spacing={4}>
              {reportList.map((report, index) => (
                <Grid item xs={4} key={index}>
                  <div
                    className={`download-name-wrapper ${
                      currentSelection.name === report.name ? "active" : ""
                    }`}
                    onClick={() => {
                      setCurrentSelection({
                        ...currentSelection,
                        name: report.name,
                        api: report.api,
                        dateRange: report.dateRange,
                        selectTestCenter: report.selectTestCenter
                      });
                    }}
                  >
                    {report.name}
                  </div>
                </Grid>
              ))}
            </Grid>
          </li>
          {currentSelection.selectTestCenter && user.role === USER_ROLES.Superuser && (
            <li>
              <h5>
                Select Test Center
              </h5>
              <Form
                onSubmit={onChange}
                render={() => (
                  <Select
                    label="Select Test Center"
                    name="test_center"
                    value={selectedTestCenter}
                    onChange={onChange}
                    error={selectTestCenterError}
                  >
                    {testCenters.map((obj, index) => (
                      <MenuItem value={obj.id} key={index}>
                        {obj.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </li>
          )}
          {currentSelection.dateRange && (
            <li>
              <h5>Choose a date range</h5>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  From:
                  <DatePicker
                    selected={new Date(currentSelection.fromDate)}
                    onChange={(date) =>
                      setCurrentSelection({
                        ...currentSelection,
                        fromDate: date,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  To:
                  <DatePicker
                    selected={new Date(currentSelection.toDate)}
                    onChange={(date) =>
                      setCurrentSelection({ ...currentSelection, toDate: date })
                    }
                  />
                </Grid>
              </Grid>
            </li>
          )}
          <li>
            <h5>Choose a format</h5>
            <Grid container spacing={4}>
              {formats.map((item, index) => (
                <Grid item xs={4} key={index}>
                  <div
                    className={`download-format-wrapper ${
                      currentSelection.format === item.type ? "active" : ""
                    }`}
                    onClick={() => {
                      setCurrentSelection({
                        ...currentSelection,
                        format: item.type,
                      });
                    }}
                  >
                    <img
                      className="criteria_image"
                      src={item.imageURL}
                      alt=""
                    />
                    <p>{item.type}</p>
                  </div>
                </Grid>
              ))}
            </Grid>
          </li>
        </ol>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="download-format-button"
            onClick={handleDownload}
            disabled={
              isLoading ||
              currentSelection.format === "" ||
              currentSelection.name === ""
            }
          >
            {isLoading ? "Creating file..." : "Download Report"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default DownloadReport;
