import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, ButtonGroup, Alert } from '@mui/material';

import { useAuthContext } from '../../hooks/useAuthContext';
import { getExaminers } from '../../services/examinerService';
import { updateExaminers } from '../../services/examinerService';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #ececec',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};

const AssignExaminerModal = ({openAssignExaminerModal, setOpenAssignExaminerModal, selectedCandidateIdForChangingExaminer}) => {
    const { user } = useAuthContext();
    const handleClose = () => setOpenAssignExaminerModal(false);

    const [speakingExaminers, setSpeakingExaminers] = useState([]);
    const [writingExaminers, setWritingExaminers] = useState([]);
    const [hasSubmitError, setHasSubmitError] = useState(false);
    const [speakingExaminer, setSpeakingExaminer] = useState('');
    const [writingExaminer, setWritingExaminer] = useState('');
    const [message, setMessage] = useState('');
    
    useEffect(() => {
        const fetchExaminers = async () => {
            try {
              const { examiners } = await getExaminers(user.test_center);
              setSpeakingExaminers(examiners.filter(e => e.grading_role === 'both' || e.grading_role === 'speaking'));
              setWritingExaminers(examiners.filter(e => e.grading_role === 'both' || e.grading_role === 'writing'));
            } catch (error) {
              console.error('Failed to fetch examiners', error);
            }
          };
        fetchExaminers();
    }, [user.test_center]);

    const onSubmit = async () => {
        if (speakingExaminer === '' || writingExaminer === '' || selectedCandidateIdForChangingExaminer === '') {
            setHasSubmitError(true);
            setMessage('Please select speaking and writing examiner!')
        }else{
            try {
                await updateExaminers(selectedCandidateIdForChangingExaminer, { speaking_examiner_id: speakingExaminer, writing_examiner_id: writingExaminer });
                handleClose();
            } catch (error) {
                setHasSubmitError(true);
                setMessage('Something went wrong!');
            }
        }
    }
    
    return (
      <div>
        <Modal
          open={openAssignExaminerModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {hasSubmitError &&
                <Alert severity="error">{message}</Alert>
            }
            <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                Change Examiners
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="speaking-select-label">Speaking Examiner</InputLabel>
                <Select
                    labelId="speaking-select-label"
                    id="speaking-select"
                    value={speakingExaminer}
                    label="Speaking Examiner"
                    onChange={(e) => setSpeakingExaminer(e.target.value)}
                >
                    {speakingExaminers.map((ex, idx) => (
                        <MenuItem value={ex.id} key={idx}>
                            {`${ex.first_name} ${ex.last_name}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 3 }}>
                <InputLabel id="writing-select-label">Writing Examiner</InputLabel>
                <Select
                    labelId="writing-select-label"
                    id="writing-select"
                    value={writingExaminer}
                    label="Writing Examiner"
                    onChange={(e) => setWritingExaminer(e.target.value)}
                >
                    {writingExaminers.map((ex, idx) => (
                        <MenuItem value={ex.id} key={idx}>
                            {`${ex.first_name} ${ex.last_name}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ mt: 3, float: 'right'}}>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={onSubmit}>Submit</Button>
                </ButtonGroup>
            </FormControl>
          </Box>
        </Modal>
      </div>
    );
}

export default AssignExaminerModal