import React from "react";
import { Grid, Divider } from "@mui/material";
import {format} from 'date-fns';
import {TableContainer, Table, TableBody, TableHead, TableRow, Paper} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { Loader } from "../../components/common";

function ShowSpeakingResponsesDetails ({ questionDetails, answer, grading, isLoading }) {
      
    return (
        <>
            <div className="grading-speaking-wrapper">
                {isLoading ? (
                    <div className="loader-display">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <audio src={answer} controls="controls" />
                        <div className="details">
                            <p>{questionDetails && questionDetails.name}</p>
                            <p>{questionDetails && questionDetails.description}</p>
                        </div>
                    </>
                )}
            </div>
            <div className="grading-marks-wrapper">
                {isLoading ? (
                    <div className="loader-display">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <h3>Grading</h3>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right">Accuracy</TableCell>
                                        <TableCell align="right">Coherence</TableCell>
                                        <TableCell align="right">Fluency</TableCell>
                                        <TableCell align="right">Phonology</TableCell>
                                        <TableCell align="right">Range</TableCell>
                                        <TableCell align="right">Final Grade</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="right">{grading.grade_breakdown.accuracy}</TableCell>
                                        <TableCell align="right">{grading.grade_breakdown.coherence}</TableCell>
                                        <TableCell align="right">{grading.grade_breakdown.fluency}</TableCell>
                                        <TableCell align="right">{grading.grade_breakdown.phonology}</TableCell>
                                        <TableCell align="right">{grading.grade_breakdown.range}</TableCell>
                                        <TableCell align="right">{grading.grade}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <h3>Timing</h3>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Answer Date</TableCell>
                                        <TableCell>Answer Time</TableCell>
                                        <TableCell>Grading Date</TableCell>
                                        <TableCell>Grading Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{format(new Date(grading.answer_time), 'dd/MM/yyyy')}</TableCell>
                                        <TableCell>{format(new Date(grading.answer_time), 'p')}</TableCell>
                                        <TableCell>{format(new Date(grading.grade_time), 'dd/MM/yyyy')}</TableCell>
                                        <TableCell>{format(new Date(grading.grade_time), 'p')}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Divider sx={{ mt: 3 }} variant="middle" />
                        <h3>Comments</h3>
                        <Grid container direction="row" component={Paper}>
                            <p style={{'padding': 20}}>
                                {grading.comments}
                            </p>
                        </Grid>
                    </>
                )}
            </div>
        </>
    );
}

export default ShowSpeakingResponsesDetails;