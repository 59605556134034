import React, {useState, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Axios from "axios";
import AuthHeader from "../../services/AuthHeader";
import { DATABASE_URL } from "../../appConstants";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useGridApiContext, GridToolbarContainer, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, GridActionsCellItem } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { ConfirmDialog } from "../../components/common";
import { USER_ROLES } from "../../appConstants";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

const CustomToolbar = ({ setRefresh }) => {
    const { user } = useAuthContext();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
  
    const apiRef = useGridApiContext();
  
    const handleDelete = () => {
      const body = checkSelection();
      if (body) {
        body
          .slice(1)
          .slice(0, -1)
          .split(",")
          .map((id, index) =>
            Axios.delete(DATABASE_URL + "/question/delete_speaking_question/" + id, {
              headers: AuthHeader(),
            }).then((response) => {
              console.log(response.data);
            })
          );
          setRefresh(true)
      }
    };
  
    const checkSelection = () => {
      const selection = apiRef.current.state.selection;
      if (!selection.length) {
        setDialogMessage("Please selecte at least one question");
        return false;
      }
      return JSON.stringify(selection);
    };
  
    return (
      <GridToolbarContainer>
        <div className="tool-bar-container">
          <div>
            {user.role === USER_ROLES.Superuser ? (
              <Button
                variant="outlined"
                color="error"
                className="btn"
                onClick={() => {
                  setDeleteOpen(true);
                  if (checkSelection()) {
                    setDialogMessage(
                      "Are you sure that you want to delete the selected question? "
                    );
                  }
                }}
              >
                Delete
              </Button>
            ) : (
              ""
            )}
            <ConfirmDialog
              title={dialogMessage}
              open={deleteOpen}
              setOpen={setDeleteOpen}
              onConfirm={handleDelete}
            />
          </div>
          <div>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
          </div>
        </div>
      </GridToolbarContainer>
    );
};

function SpeakingQuestions() {
    const [pageSize, setPageSize] = useState(10);
    const [gradingList, setGradingList] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const { isLoading, startLoading, stopLoading } = useLoadingContext();

    const navigate = useNavigate()

    const columns = [
        {
            field: "actions",
            type: "actions",
            width: 50,
            sortable: false,
            filterable: false,
            headerName: "Edit",
            getActions: (params) => [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit Candidate"
                onClick={editQuestion(params.row.id)}
              />,
            ],
        },
        { field: "id", headerName: "Ques ID", flex: 1 },
        { field: "name", headerName: "Name", flex: 1 },
        { field: "description", headerName: "Description", flex: 1 },
        { field: "time", headerName: "Time", flex: 1 },
        { field: "number", headerName: "Question Number", flex: 1 },
    ];

    const editQuestion = useCallback(
        (id) => () => {
          navigate("/questions/speaking-questions/" + id);
        },
        []
    );

    useEffect(() => {
        startLoading();

        Axios.get(DATABASE_URL + "/question/get_all_questions/speaking", {
            headers: AuthHeader(),
        }).then((response) => {
            setGradingList(response.data.questions);
            stopLoading();
            setRefresh(false);
        }).catch((error) => {
          console.log('Error: ', error);
          stopLoading();
        });
    }, [refresh])

    return (
        <>
            <h1>Speaking Questions</h1>
            <div style={{ height: 600, width: "100%" }}>
                {isLoading ? (
                  <div className="loader-display">
                    <Loader />
                  </div>
                ) : (
                  <DataGrid
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 25, 50]}
                    pagination
                    disableColumnMenu
                    initialState={{
                        sorting: {
                        sortModel: [{ field: "id", sort: "asc" }],
                        },
                    }}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{ toolbar: { setRefresh } }}
                    checkboxSelection
                    rows={gradingList}
                    columns={columns}
                  />
                )}
            </div>
        </>
    );
}

export default SpeakingQuestions;
