import React, {useState, useEffect} from "react";
import { Grid, Button, Alert, Stack } from "@mui/material";
import {
  TextField,
  makeValidate,
  makeRequired,
} from "mui-rff";
import EditIcon from "@mui/icons-material/Edit";
import * as Yup from "yup";
import { Form } from "react-final-form";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";

import { useNavigate, useParams } from "react-router-dom";

function UpdateQuestionGroup() {
    const params = useParams();
    const [message, setMessage] = useState();
    const [isDisabled, setIsDisabled] = useState(true);
    const [questionGroup, setQuestionGroup] = useState(null)
    const navigate = useNavigate()
    
    useEffect(() => {
        Axios.get(
            DATABASE_URL + "/question/get_question_group_by_id/" + params.id,
            { headers: AuthHeader() }
        )
        .then((response) => {
            let q_group = response.data.question_group[0]
            setQuestionGroup(q_group)
            console.log(response.data.question_group);
        })
        .catch((error) => {
            console.log(error.response);
        });
    }, [])

    const schema = Yup.object().shape({
        number: Yup.number().required("Group Number is required"),
    });

    const validate = makeValidate(schema);

    const required = makeRequired(schema);

    const enableEdit = () => {
        setIsDisabled(false);
    };

    const speakingQuesFormFields = [
        <TextField
            label="Group Number"
            name="number"
            placeholder="Group Number"
            type="number"
            required={required.number}
            disabled={isDisabled}
        />
    ];

    const onSubmit = async (values) => {
        setMessage("");

        Axios.put(
            DATABASE_URL + "/question/update_question_group",
            { ...values },
            { headers: AuthHeader() }
        )
        .then((response) => {
            setMessage("Question Group Updated!");
            console.log(response.data);
            navigate("/questions/manage-question-group");
        })
        .catch((error) => {
            console.log(error.response);
        });
    };

    const onCancel = () => {
        navigate("/questions/manage-question-group");
    };

    return (
        <div>
            <div className="title-with-button">
                <h1>Update Question Group</h1>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<EditIcon />}
                    onClick={enableEdit}
                    disabled={!isDisabled}
                >
                    Enable Edit
                </Button>
            </div>
            <Stack spacing={2}>
                <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={questionGroup}
                render={({
                    hasSubmitErrors,
                    handleSubmit,
                    submitting,
                    form,
                    values,
                }) => (
                    <form onSubmit={handleSubmit} className="add-user-form">
                        <Grid container direction="row" spacing={4}>
                            {speakingQuesFormFields.map((field, index) => (
                            <Grid item xs={12} key={index}>
                                {field}
                            </Grid>
                            ))}
                        </Grid>
                        <Grid container spacing={4} className="form-button-group">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="large"
                                    disabled={submitting || isDisabled}
                                >
                                    Update
                                </Button>
                                </Grid>
                                <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    size="large"
                                    disabled={submitting}
                                    onClick={onCancel}
                                >
                                    BACK
                                </Button>
                            </Grid>
                        </Grid>
                        {hasSubmitErrors && <Alert severity="error">error</Alert>}
                        {/* <pre>{JSON.stringify(values)}</pre> */}
                    </form>
                )}
                />
                {message && <Alert severity="success">{message}</Alert>}
            </Stack>
        </div>
    )
}

export default UpdateQuestionGroup