import React, {useState} from "react";
import { Grid, Button, Alert, Stack } from "@mui/material";
import {
  TextField,
  makeValidate,
  makeRequired,
} from "mui-rff";
import * as Yup from "yup";
import { Form } from "react-final-form";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";

function AddQuestionGroup() {
    const [message, setMessage] = useState();
    const [errMessage, setErrMessage] = useState();

    const schema = Yup.object().shape({
        group_number: Yup.number().required("Group Number is required"),
    });

    const validate = makeValidate(schema);

    const required = makeRequired(schema);

    const speakingQuesFormFields = [
        <TextField
            label="Group Number"
            name="group_number"
            placeholder="Group Number"
            type="number"
            required={required.group_number}
        />
    ];

    const onSubmit = async (values) => {
        setMessage("");
        setErrMessage("");

        Axios.post(
            DATABASE_URL + "/question/add_question_group",
            { ...values },
            { headers: AuthHeader() }
        )
        .then((response) => {
            setMessage("New Question Group Created!");
            console.log(response.data);
        })
        .catch((error) => {
            setErrMessage(error.response.data.error);
            console.log(error.response);
        });
    };

    return (
        <div>
            <h1>Create New Group</h1>
            <Stack spacing={2}>
                {message && <Alert severity="success">{message}</Alert>}
                {errMessage && <Alert severity="warning">{errMessage}</Alert>}
                <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    render={({
                        hasSubmitErrors,
                        handleSubmit,
                        submitting,
                        form,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit} className="add-user-form">
                            <Grid container direction="row" spacing={4}>
                                {speakingQuesFormFields.map((field, index) => (
                                <Grid item xs={12} key={index}>
                                    {field}
                                </Grid>
                                ))}
                            </Grid>
                            <Grid container spacing={4} className="form-button-group" justifyContent="flex-end">
                                <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="large"
                                    disabled={submitting}
                                >
                                    CREATE
                                </Button>
                                </Grid>
                            </Grid>
                            {hasSubmitErrors && <Alert severity="error">error</Alert>}
                            {/* <pre>{JSON.stringify(values)}</pre> */}
                        </form>
                    )}
                />
            </Stack>
        </div>
    )
}

export default AddQuestionGroup