import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";
import AuthHeader from "../../services/AuthHeader";
import { DataGrid } from "@mui/x-data-grid";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Button } from "@mui/material";
import * as FaIcons from "react-icons/fa"
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

function Graded() {
  const { user } = useAuthContext();
  const [gradingList, setGradingList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const { isLoading, startLoading, stopLoading } = useLoadingContext();

  const columns = [
    { field: "id", headerName: "Grading ID", flex: 1 },
    { field: "candidate_full_name", headerName: "Candidate", flex: 1 },
    { field: "examiner_full_name", headerName: "Examiner", flex: 1 },
    { field: "question_type_name", headerName: "Question Type", flex: 1 },
    { field: "grade", headerName: "Grade", flex: 1 },
    { field: "comments", headerName: "Comments", flex: 1 },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      sortable: false,
      filterable: false,
      headerName: "View",
      getActions: (params) => [
        <Button onClick={viewDetails(params.id)}>
          <FaIcons.FaEye />
        </Button>,
      ],
    },
  ];

  const viewDetails = useCallback(
    (id) => () => {
      navigate(id.toString());
    },
    []
  );

  useEffect(() => {
    startLoading();

    Axios.get(DATABASE_URL + "/grading/graded/" + user.test_center, {
        headers: AuthHeader(),
    }).then((response) => {
        let filteredList = response.data.final_grades;
        setGradingList(filteredList);
        stopLoading();
    }).catch((error) => {
      console.log('Error: ', error);
      stopLoading();
    });
  }, []);
  
  return (
    <div>
      <h1>Graded</h1>
      <div style={{ height: 600, width: "100%" }}>
        {isLoading ? (
          <div className="loader-display">
            <Loader />
          </div>
        ) : (
          <DataGrid 
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50]}
            pagination
            disableColumnMenu
            initialState={{
              sorting: {
                sortModel: [{ field: "deadline", sort: "asc" }],
              },
            }}
            rows={gradingList}
            columns={columns}
          />
        )}
      </div>
    </div>
  );
}

export default Graded;
