import React from "react";
import { SecondaryNavbar } from "../../components/common";
import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import { USER_ROLES } from "../../appConstants";
import { useAuthContext } from "../../hooks/useAuthContext";

const testCenterPage = [
  <NavLink to="pending-for-grading"> <FaIcons.FaListOl className="fa-i" /> Start Grading</NavLink>,
  <NavLink to="criteria"><FaIcons.FaLayerGroup className="fa-i" /> Grading Criteria</NavLink>
];

const gradedPage = <NavLink to="graded"> <FaIcons.FaLayerGroup className="fa-i" /> Graded</NavLink>

const subMenu = [];
subMenu[USER_ROLES.Administrator] = [...testCenterPage, gradedPage];
subMenu[USER_ROLES.Proctor] = testCenterPage
subMenu[USER_ROLES.Examiner] = testCenterPage

function Grading() {
  const { user } = useAuthContext();
  return (
    <div className="page-content-container">
      <SecondaryNavbar menu={subMenu[user.role]} />
      <div className="page-content">
        <Outlet />
      </div>
    </div>
  );
}

export default Grading;
