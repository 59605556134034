import React from "react";
import { Grid, Divider } from "@mui/material";
import {format} from 'date-fns';
import {TableContainer, Table, TableBody, TableHead, TableRow, Paper} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { Loader } from "../../components/common";


function ShowWritingResponsesDetails ({ questionDetails, answer, grading, isLoading }) {
    return (
        <>
            <div className="grading-writing-wrapper">
                {isLoading ? (
                    <div className="loader-display">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className="details">
                            <p>{questionDetails && questionDetails.description}</p>
                        </div>
                        <p className="answer">
                            {answer.split("\n").map((i, key) => {
                                return <span className="answer-text" key={key}>{i}</span>;
                            })}
                        </p>
                    </>
                )}
            </div>
            <div className="grading-marks-wrapper">
                {isLoading ? (
                    <div className="loader-display">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <h3>Grading</h3>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right">Accuracy</TableCell>
                                        <TableCell align="right">Coherence</TableCell>
                                        <TableCell align="right">Range</TableCell>
                                        <TableCell align="right">Final Grade</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="right">{grading.grade_breakdown.accuracy ? grading.grade_breakdown.accuracy : 'Null'}</TableCell>
                                        <TableCell align="right">{grading.grade_breakdown.coherence ? grading.grade_breakdown.coherence : 'Null'}</TableCell>
                                        <TableCell align="right">{grading.grade_breakdown.range ? grading.grade_breakdown.range : 'Null'}</TableCell>
                                        <TableCell align="right">{grading.grade ? grading.grade : 'Null'}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <h3>Timing</h3>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Answer Date</TableCell>
                                        <TableCell>Answer Time</TableCell>
                                        <TableCell>Grading Date</TableCell>
                                        <TableCell>Grading Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{format(new Date(grading.answer_time), 'dd/MM/yyyy')}</TableCell>
                                        <TableCell>{format(new Date(grading.answer_time), 'p')}</TableCell>
                                        <TableCell>{format(new Date(grading.grade_time), 'dd/MM/yyyy')}</TableCell>
                                        <TableCell>{format(new Date(grading.grade_time), 'p')}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Divider sx={{ mt: 3 }} variant="middle" />
                        <h3>Comments</h3>
                        <Grid container direction="row" component={Paper}>
                            <p style={{'padding': 20}}>
                                {grading.comments}
                            </p>
                        </Grid>
                    </>
                )}
            </div>
        </>
    );
}

export default ShowWritingResponsesDetails;