import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";
import AuthHeader from "../../services/AuthHeader";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { format, formatDistanceToNow, addDays } from "date-fns";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

function PendingForGrading() {
  const { user } = useAuthContext();
  const [gradingList, setGradingList] = useState([]);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const { isLoading, startLoading, stopLoading } = useLoadingContext();

  const columns = [
    { field: "answer_time", headerName: "Test Date", flex: 1 },
    { field: "id", headerName: "Test ID", flex: 1 },
    { field: "examiner_name", headerName: "Examiner", flex: 1 },
    { field: "deadline", headerName: "Deadline", flex: 1 },
    { field: "question_type", headerName: "Question Type", flex: 1 },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      sortable: false,
      filterable: false,
      getActions: (params) => [
        <Button variant="outlined" onClick={editGrading(params.id)}>
          {user.role === 1 ? 'View' : 'Grade'}
        </Button>,
      ],
    },
  ];

  const editGrading = useCallback(
    (id) => () => {
      navigate(id.toString());
    },
    []
  );

  useEffect(() => {
    startLoading();

    Axios.get(DATABASE_URL + "/grading/test_center/" + user.test_center + "/examiner/" + user.userId, {
      headers: AuthHeader(),
    }).then((response) => {
      let filteredList = response.data.grading;
      filteredList.forEach((item) => {
        const test_date = new Date(item.answer_time);
        let dateOffset = 5;
        if (test_date.getDay() > 1) {
          dateOffset = 7;
        }
        item.deadline = formatDistanceToNow(
          addDays(new Date(item.answer_time), dateOffset),
          {
            addSuffix: true,
          }
        );
        item.answer_time = format(new Date(item.answer_time), "dd/MM/yyyy");
      });
      
      setGradingList(filteredList);
      stopLoading();
    }).catch((error) => {
      console.log('Error: ', error);
      stopLoading();
    });
  }, []);

  return (
    <div>
      <h1>Pending For Grading</h1>
      <div style={{ height: 600, width: "100%" }}>
        {isLoading ? (
          <div className="loader-display">
            <Loader />
          </div>
        ) : (
          <DataGrid
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50]}
            pagination
            disableColumnMenu
            initialState={{
              sorting: {
                sortModel: [{ field: "deadline", sort: "asc" }],
              },
            }}
            rows={gradingList}
            columns={columns}
          />
        )}
      </div>
    </div>
  );
}

export default PendingForGrading;
