import React, { useState } from "react";
import { Grid, MenuItem, Stack, Divider } from "@mui/material";
import { Select } from "mui-rff";
import { Form } from "react-final-form";
import AddProctor from "./AddProctor";
import AddExaminer from "./AddExaminer";
import { useLoadingContext } from "../../hooks/useLoadingContext";

function AddUser() {
  const [formType, setFormType] = useState('')
  const { isLoading, startLoading, stopLoading } = useLoadingContext();

  const onSubmit = () => {
        
  }

  return (
    <div>
      <Stack spacing={2}>
        <h1>Add New User</h1>
        <Grid container direction="row">
          <Grid sx={{ mb: 1 }} item xs={12}>
              <Form
                  onSubmit={onSubmit}
                  render={() => (
                      <Select label="Select User Type" name="select_user_type" placeholder="Select User Type" value={formType} onChange={(e) => {setFormType(e.target.value)}}>
                          <MenuItem value="examiner">Examiner</MenuItem>
                          <MenuItem value="proctor">Proctor</MenuItem>
                      </Select>
                  )}
              />
          </Grid>
        </Grid>
        <Divider variant="middle" />
        {formType === 'proctor' && <AddProctor 
          isLoading={isLoading} 
          startLoading={startLoading}
          stopLoading={stopLoading}
        />}
        {formType === 'examiner' && <AddExaminer 
          isLoading={isLoading} 
          startLoading={startLoading}
          stopLoading={stopLoading}
        />}
      </Stack>
    </div>
  );
}

export default AddUser;
