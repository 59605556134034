import React, { useState, useEffect } from "react";
import { Grid, Divider } from "@mui/material";
import { Form } from "react-final-form";
import { DATABASE_URL } from "../../appConstants";
import AuthHeader from "../../services/AuthHeader";
import {
  Select,
} from "mui-rff";
import MenuItem from "@mui/material/MenuItem";
import AddSpeakingQuestion from "./AddSpeakingQuestion";
import AddWritingQuestion from "./AddWritingQuestion";
import Axios from "axios";
import { useLoadingContext } from "../../hooks/useLoadingContext";

function AddQuestions() {
    const [formType, setFormType] = useState('');
    const [questionGroups, setQuestionGroups] = useState([])
    const { isLoading, startLoading, stopLoading } = useLoadingContext();

    const onSubmit = () => {
        
    }

    useEffect(() => {
        Axios.get(DATABASE_URL + "/question/get_question_groups/", {
            headers: AuthHeader(),
        }).then((response) => {
            console.log(response.data.question_groups);
            setQuestionGroups(response.data.question_groups)
        });
    }, [])

    return (
        <div>
            <h1>Add New Question</h1>
            <Grid container direction="row" spacing={4}>
                <Grid sx={{ mb: 3 }} item xs={12}>
                    <Form
                        onSubmit={onSubmit}
                        render={() => (
                            <Select label="Question Type" name="question_type" placeholder="Question Type" value={formType} onChange={(e) => {setFormType(e.target.value)}}>
                                <MenuItem value="speaking-question">Speaking Question</MenuItem>
                                <MenuItem value="writing-question">Writing Question</MenuItem>
                            </Select>
                        )}
                    />
                </Grid>
            </Grid>
            <Divider sx={{ mb: 3 }} variant="middle" />
            {formType === 'speaking-question' && <AddSpeakingQuestion 
                questionGroups={questionGroups.filter(q => q.id !== 5 && q.id !== 6)} 
                isLoading={isLoading}
                startLoading={startLoading}
                stopLoading={stopLoading}
            />}
            {formType === 'writing-question' && <AddWritingQuestion 
                questionGroups={questionGroups.filter(q => q.id === 5 || q.id === 6)} 
                isLoading={isLoading}
                startLoading={startLoading}
                stopLoading={stopLoading}
            /> }
            
        </div>
    );
}

export default AddQuestions