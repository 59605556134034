import React from "react";
import { SecondaryNavbar } from "../../components/common";
import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import { useAuthContext } from "../../hooks/useAuthContext";
import { USER_ROLES } from "../../appConstants";

import "./Reports.css";

const reportsPage = <NavLink to="download-report"> <FaIcons.FaFileContract className="fa-i" /> Reports</NavLink>
const formsPage = <NavLink to="forms"> <FaIcons.FaWpforms className="fa-i" /> Forms</NavLink>
const subMenu = [];
subMenu[USER_ROLES.Administrator] = [reportsPage, formsPage];
subMenu[USER_ROLES.Examiner] = [formsPage];
subMenu[USER_ROLES.Proctor] = [formsPage];
subMenu[USER_ROLES.Superuser] = [reportsPage];


function Reports() {
  const { user } = useAuthContext();
  return (
    <div className="page-content-container">
      <SecondaryNavbar menu={subMenu[user.role]} />
      <div className="page-content">
        <Outlet />
      </div>
    </div>
  );
}

export default Reports;
