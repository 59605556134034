import React, { useEffect, useState, useCallback } from "react";
import { DATABASE_URL, TYPE_OF_TEST } from "../../appConstants";
import { Button } from "@mui/material";
import Axios from "axios";
import AuthHeader from "../../services/AuthHeader";
import {
  DataGrid,
  GridToolbarContainer,
  useGridApiContext,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { ConfirmDialog } from "../../components/common";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { USER_ROLES } from "../../appConstants";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

const CustomToolbar = ({ setRefresh }) => {
  const { user } = useAuthContext();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  
  const apiRef = useGridApiContext();

  const handleDelete = () => {
    const body = checkSelection();
    let formatId = body
      .replaceAll('"', "'")
      .replaceAll("[", "")
      .replaceAll("]", "");
    if (body) {
      Axios.put(
        DATABASE_URL + "/group/delete/",
        { id: formatId.toString() },
        { headers: AuthHeader() }
      ).then((response) => {
        setRefresh(true)
      });
    }
  };

  const checkSelection = () => {
    const selection = apiRef.current.state.selection;
    if (!selection.length) {
      setDialogMessage("Please selecte at least one group ");
      return false;
    }
    return JSON.stringify(selection);
  };

  return (
    <GridToolbarContainer>
      <div className="tool-bar-container">
        <div>
          {user.role === USER_ROLES.Administrator ? (
            <Button
              variant="outlined"
              color="error"
              className="btn"
              onClick={() => {
                setDeleteOpen(true);
                if (checkSelection()) {
                  setDialogMessage(
                    "Are you sure that you want to delete the selected groups? "
                  );
                }
              }}
            >
              Delete
            </Button>
          ) : (
            ""
          )}

          <ConfirmDialog
            title={dialogMessage}
            open={deleteOpen}
            setOpen={setDeleteOpen}
            onConfirm={handleDelete}
          />
        </div>
        <div>
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </div>
      </div>
    </GridToolbarContainer>
  );
};

function ManageGroup() {
  const [groupList, setGroupList] = useState([]);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [refresh, setRefresh] = useState(false)
  const { user } = useAuthContext();
  const { isLoading, startLoading, stopLoading } = useLoadingContext();

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 80,
      sortable: false,
      filterable: false,
      headerName: "Edit",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit Group"
          onClick={editGroup(params.id)}
        />,
      ],
    },
    { field: "name", headerName: "Group Name", width: 300 },
    { field: "count", headerName: "Number of Candidates", width: 200 },
    { field: "type_of_test_id", headerName: "Type of Test", width: 200 },
    { field: "test_date", headerName: "Test Date", width: 200 },
  ];

  const editGroup = useCallback(
    (id) => () => {
      navigate("/groups/manage-groups/" + id);
    },
    []
  );

  useEffect(() => {
    startLoading();

    Axios.get(DATABASE_URL + "/group/" + user.test_center, {
      headers: AuthHeader(),
    }).then((response) => {
      let filteredList = response.data.groups.filter((item) => item.id !== "0");
      filteredList.forEach((item) => {
        item.test_date = format(new Date(item.test_date), "yyyy-MM-dd H:mm");
        item.type_of_test_id = TYPE_OF_TEST[item.type_of_test_id];
      });
      setGroupList(filteredList);
      stopLoading();
    }).catch((error) => {
      console.log('Error: ', error);
      stopLoading();
    });
    setRefresh(false)
  }, [refresh]);

  return (
    <div>
      <h1>Manage Groups</h1>
      <div style={{ height: 600, width: "100%" }}>
        {isLoading ? (
          <div className="loader-display">
            <Loader />
          </div>
        ) : (
          <DataGrid
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50]}
            pagination
            components={{
              Toolbar: CustomToolbar,
            }}
            componentsProps={{ toolbar: { setRefresh } }}
            checkboxSelection
            rows={groupList}
            columns={columns}
          />
        )}
      </div>
    </div>
  );
}

export default ManageGroup;
