import React from "react";
import { NavLink } from "react-router-dom";
import * as FaIcons from "react-icons/fa"

import { USER_ROLES } from "../../../appConstants";
import { useAuthContext } from "../../../hooks/useAuthContext";

import "./Navbar.css";

const testCenterPage = <NavLink to="test-centers"> <FaIcons.FaUserCircle className="m-r" /> Test Centers</NavLink>;
const profilePage = <NavLink to="profile"> <FaIcons.FaUserCircle className="m-r" /> Profile</NavLink>;
const userPage = <NavLink to="user"> <FaIcons.FaUser className="m-r" /> User</NavLink>;
const licensesPage = <NavLink to="licenses"> <FaIcons.FaWallet className="m-r" /> Licenses</NavLink>;
const candidatesPage = <NavLink to="candidate"> <FaIcons.FaUserShield className="m-r" /> Candidates</NavLink>;
const groupsPage = <NavLink to="groups"> <FaIcons.FaUsers className="m-r" /> Groups</NavLink>;
const gradingPage = <NavLink to="grading"> <FaIcons.FaLayerGroup className="m-r" /> Grading</NavLink>;
const reportsPage = <NavLink to="reports"> <FaIcons.FaFileAlt className="m-r" /> Reports</NavLink>;
const questionsPage = <NavLink to="questions"> <FaIcons.FaQuestionCircle className="m-r" /> Questions</NavLink>
const aDashboard = <div className="d-tag"> Admin Dashboard </div>;
const eDashboard = <div className="d-tag"> Examiner Dashboard </div>;
const pDashboard = <div className="d-tag"> Proctor Dashboard </div>;
const sDashboard = <div className="d-tag"> Superuser Dashboard </div>;

const menu = [];
menu[USER_ROLES.Superuser] = [
  sDashboard,
  testCenterPage,
  userPage,
  questionsPage,
  reportsPage
];
menu[USER_ROLES.Administrator] = [
  aDashboard,
  profilePage,
  userPage,
  licensesPage,
  candidatesPage,
  groupsPage,
  gradingPage,
  reportsPage
];
menu[USER_ROLES.Proctor] = [
  pDashboard,
  profilePage,
  candidatesPage,
  groupsPage,
  reportsPage,
];
menu[USER_ROLES.Examiner] = [eDashboard, profilePage, gradingPage, reportsPage];

function Navbar() {
  const { user } = useAuthContext();
  return (
    <nav className="navbar">
      <ul>
        {menu[user.role].map((link, index) => {
          return <li key={index}>{link}</li>;
        })}
      </ul>
    </nav>
  );
}

export default Navbar;
