import React, { useState } from "react";
import { Grid, Button, Alert, Stack, MenuItem } from "@mui/material";
import {
  TextField,
  makeValidate,
  makeRequired,
  Select
} from "mui-rff";
import * as Yup from "yup";
import { Form } from "react-final-form";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";
import { Loader } from "../../components/common";

function AddWritingQuestion({questionGroups, isLoading, startLoading, stopLoading}) {
    const [message, setMessage] = useState();
    const [errMessage, setErrMessage] = useState();

    const schema = Yup.object().shape({
        question_name: Yup.string().required("Question name is required"),
        question_description: Yup.string().required("Question Description is required"),
        question_time: Yup.string().required("Question time is required"),
        words_limit: Yup.string().required("Words Limit is required"),
        words_limit_char: Yup.string().required("Words Limit Charater is required"),
        question_group_id: Yup.number().required("Question Group is required")
    });

    const validate = makeValidate(schema);

    const required = makeRequired(schema);

    const writingQuesFormFields = [
        <TextField
            label="Question Title"
            name="question_name"
            placeholder="First Name"
            type="text"
            required={required.question_name}
        />,
        <TextField
            label="Question Description"
            name="question_description"
            id="outlined-textarea"
            placeholder="Question Description"
            type="text"
            required={required.question_description}
            multiline
            rows={3}
        />,
        <Grid container direction="row" spacing={4}>
            <Grid item xs={6}>
                <TextField
                    label="Question Time"
                    name="question_time"
                    placeholder="Question Time"
                    type="number"
                    required={required.question_time}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Words Limit e.g:(80 to 100)"
                    name="words_limit"
                    placeholder="Words Limit"
                    type="text"
                    required={required.words_limit}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Words Limit in Characters"
                    name="words_limit_char"
                    placeholder="Words Limit in Characters"
                    type="number"
                    required={required.words_limit_char}
                />
            </Grid>
            <Grid item xs={6}>
                <Select
                    label="Question Group"
                    name="question_group_id"
                    placeholder="Question Group"
                    required={required.question_group_id}
                >
                    {questionGroups.map((obj, index) => (
                        <MenuItem value={obj.id} key={index}>
                            {obj.number}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </Grid>,
    ];

    const onSubmit = async (values) => {
        startLoading();
        setMessage("");
        setErrMessage("");
        values['question_type'] = 'writing';

        Axios.post(
            DATABASE_URL + "/question/create_new_question",
            { ...values },
            { headers: AuthHeader() }
        )
        .then((response) => {
            setMessage("New Writing Question Created!");
            console.log(response.data);
            stopLoading();
        })
        .catch((error) => {
            setErrMessage("Something wrong!");
            console.log(error.response.data.message);
            stopLoading();
        });
    };

    return (
        <div>
            <Stack spacing={2}>
                {isLoading ? (
                    <div className="loader-display">
                        <Loader />
                    </div>
                ) : (
                    <>
                        {message && <Alert severity="success">{message}</Alert>}
                        {errMessage && <Alert severity="warning">{errMessage}</Alert>}
                        <Form
                            onSubmit={onSubmit}
                            validate={validate}
                            render={({
                                hasSubmitErrors,
                                handleSubmit,
                                submitting,
                                form,
                                values,
                            }) => (
                                <form onSubmit={handleSubmit} className="add-user-form">
                                    <Grid container direction="row" spacing={4}>
                                        {writingQuesFormFields.map((field, index) => (
                                        <Grid item xs={12} key={index}>
                                            {field}
                                        </Grid>
                                        ))}
                                    </Grid>
                                    <Grid container spacing={4} className="form-button-group" justifyContent="flex-end">
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                size="large"
                                                disabled={submitting}
                                            >
                                                CREATE
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {hasSubmitErrors && <Alert severity="error">error</Alert>}
                                    {/* <pre>{JSON.stringify(values)}</pre> */}
                                </form>
                            )}
                        />
                    </>
                )}
            </Stack>
        </div>
    );
    
}


export default AddWritingQuestion;