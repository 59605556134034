import React from "react";
import { SecondaryNavbar } from "../../components/common";
import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import * as FaIcons from "react-icons/fa"
import { USER_ROLES } from "../../appConstants";
import { useAuthContext } from "../../hooks/useAuthContext";

const userPages = [
  <NavLink to="manage-user"> <FaIcons.FaUserCog className="fa-i" /> Manage Users</NavLink>,
];

const superUserPage = [
  <NavLink to="add-administrator"> <FaIcons.FaUserPlus className="fa-i" /> Add Administrator</NavLink>,
  <NavLink to="add-user"> <FaIcons.FaUserPlus className="fa-i" /> Add user</NavLink>,
  <NavLink to="manage-user"> <FaIcons.FaUserCog className="fa-i" /> Manage Users</NavLink>
]

const subMenu = []
subMenu[USER_ROLES.Superuser] = superUserPage
subMenu[USER_ROLES.Administrator] = userPages

function User() {
  const { user } = useAuthContext();
  return (
    <div className="page-content-container">
      <SecondaryNavbar menu={subMenu[user.role]} />
      <div className="page-content">
        <Outlet />
      </div>
    </div>
  );
}

export default User;
