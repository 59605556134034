import React from "react";
import { SecondaryNavbar } from "../../components/common";
import { Outlet, NavLink } from "react-router-dom";
import * as FaIcons from "react-icons/fa"

import { useAuthContext } from "../../hooks/useAuthContext";
import { USER_ROLES } from "../../appConstants";

import "./Group.css"

const addGroup = <NavLink to="add-group"> <FaIcons.FaUserPlus className="fa-i" /> Add Group</NavLink>;
const manageGroup = <NavLink to="manage-groups"> <FaIcons.FaUsersCog className="fa-i" /> Manage Group</NavLink>;

function Group() {
  const { user } = useAuthContext();

  return (
    <div className="page-content-container">
      {user.role === USER_ROLES.Administrator ? (
        <SecondaryNavbar menu={[addGroup, manageGroup]} />
      ) : (
        <SecondaryNavbar menu={[manageGroup]} />
      )}
      <div className="page-content">
        <Outlet />
      </div>
    </div>
  );
}

export default Group;
