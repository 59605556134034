import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Grid, Button, Alert, MenuItem, Stack } from "@mui/material";
import { TextField, Select, makeValidate, makeRequired } from "mui-rff";
import * as Yup from "yup";
import { Form } from "react-final-form";
import { SUPERUSER_ROLE } from "../../appConstants";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";
import * as XLSX from "xlsx/xlsx.js";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

function AddAministrator() {
    const { user } = useAuthContext();
    const [message, setMessage] = useState();
    const [errMessage, setErrMessage] = useState();
    const [testCenter, setTestCenters] = useState([])
    const { isLoading, startLoading, stopLoading } = useLoadingContext();

    const schema = Yup.object().shape({
        first_name: Yup.string().required("First Name is required"),
        last_name: Yup.string().required("Last Name is required"),
        email: Yup.string().email().required("Email is required"),
        role: Yup.string().required(),
        new_pass: Yup.string()
            .matches(
                /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,24}$/,
                "Password must include at least one lower case, one upper case, and a number. "
            )
            .min(8, "Minimum of 8 characters in a password")
            .max(24, "Maximum of 24 characters in a password")
            .required("Must enter new password"),
        retype_pass: Yup.string()
            .required("Must retype new password")
            .oneOf([Yup.ref("new_pass"), null], "Passwords must match"),
        test_center: Yup.number().required("Test Center is required"),
    });

    const validate = makeValidate(schema);

    const required = makeRequired(schema);

    const formFields = [
        <TextField
          label="First Name"
          name="first_name"
          placeholder="First Name"
          type="text"
          required={required.first_name}
        />,
        <TextField
          label="Last Name"
          name="last_name"
          placeholder="Last Name"
          type="text"
          required={required.last_name}
        />,
        <TextField
          label="Email"
          name="email"
          placeholder="Email"
          type="email"
          required={required.email}
        />,
        <Select
          label="Role"
          name="role"
          placeholder="Role"
          required={required.role}
        >
          {SUPERUSER_ROLE.map((o, idx) => (
            <MenuItem value={idx} key={idx}>
              {o}
            </MenuItem>
          ))}
        </Select>,
        <TextField
          label="Password"
          name="new_pass"
          placeholder="Password"
          type="password"
          required={required.new_pass}
        />,
        <TextField
          label="Re-enter Password"
          name="retype_pass"
          placeholder="Re-enter Password"
          type="password"
          required={required.retype_pass}
        />,
        <Select
          label="Test Center"
          name="test_center"
          placeholder="Test Center"
          required={required.test_center}
        >
          {testCenter.map((obj, index) => (
            <MenuItem value={obj.id} key={index}>
              {obj.name}
            </MenuItem>
          ))}
        </Select>,
    ];

    const onSubmit = async (values) => {
        startLoading();
        setMessage("");
        setErrMessage("");
        const newUser = {
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name,
          role: values.role,
          password: values.new_pass,
          status: 1,
          test_center: values.test_center,
          grading_role: null
        };
    
        Axios.post(
          DATABASE_URL + "/user",
          { ...newUser },
          { headers: AuthHeader() }
        )
          .then((response) => {
            setMessage("New User Created!");
            console.log(response.data);
            stopLoading();
          })
          .catch((error) => {
            setErrMessage(error.response.data.error);
            console.log(error);
            stopLoading();
          });
    };

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);
            console.log(json);
            Axios.post(
              DATABASE_URL + "/user/import",
              { data: json, test_center: user.test_center },
              { headers: AuthHeader() }
            )
              .then((response) => {
                setMessage(response.data.message);
                console.log(response.data);
              })
              .catch((error) => {
                setErrMessage("Something wrong!");
              });
          };
          reader.readAsArrayBuffer(e.target.files[0]);
        }
    };

    useEffect(() => {
        startLoading();

        Axios.get(
          DATABASE_URL + "/test_center",
          { headers: AuthHeader() }
        )
          .then((response) => {
            setTestCenters(response.data.testCenter)
            console.log(response.data.testCenter);
            stopLoading();
          })
          .catch((error) => {
            setErrMessage("Something wrong!");
            console.log(error.response.data.message);
            stopLoading();
          });
    }, [])

    return (
        <div>
            <Stack spacing={2}>
                {message && <Alert severity="success">{message}</Alert>}
                {errMessage && <Alert severity="warning">{errMessage}</Alert>}
                <h1>Add New User</h1>
                {isLoading ? (
                  <div className="loader-display">
                    <Loader />
                  </div>
                ) : (
                  <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    render={({
                        hasSubmitErrors,
                        handleSubmit,
                        submitting,
                        form,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit} className="add-user-form">
                        <Grid container direction="row" spacing={4}>
                            {formFields.map((field, index) => (
                            <Grid item xs={6} key={index}>
                                {field}
                            </Grid>
                            ))}
                        </Grid>
                        <Grid container spacing={4} className="form-button-group" justifyContent="flex-end">
                            <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                size="large"
                                disabled={submitting}
                            >
                                CREATE
                            </Button>
                            </Grid>
                            <Grid item>
                            <Button
                                variant="contained"
                                color="success"
                                size="large"
                                component="label"
                                onChange={readUploadFile}
                            >
                                Import
                                <input type="file" hidden />
                            </Button>
                            </Grid>
                            <Grid item>
                            <Button variant="outlined" color="primary" size="large">
                                <a href="/user_template.xlsx" download>
                                Template
                                </a>
                            </Button>
                            </Grid>
                        </Grid>
                        {hasSubmitErrors && <Alert severity="error">error</Alert>}
                        {/* <pre>{JSON.stringify(values)}</pre> */}
                        </form>
                    )}
                  />
                )}
            </Stack>
        </div>
    )
}

export default AddAministrator