import React from "react";

import "./SecondaryNavbar.css";

function SecondaryNavbar(props) {
  return (
    <nav className="secondary-navbar">
      <ul>
        {props.menu.map((link, index) => {
          return <li key={index}>{link}</li>;
        })}
      </ul>
    </nav>
  );
}

export default SecondaryNavbar;
