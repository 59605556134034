import React, { useEffect, useState, useCallback } from "react";
import { DATABASE_URL } from "../../appConstants";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Button } from "@mui/material";
import Axios from "axios";
import AuthHeader from "../../services/AuthHeader";
import {
  DataGrid,
  GridToolbarContainer,
  useGridApiContext,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { ROLE, STATUS } from "../../appConstants";
import { ConfirmDialog } from "../../components/common";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import './User.css'
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

const CustomToolbar = ({ setRefresh, startLoading, stopLoading }) => {
  const [reactivateOpen, setReactivateOpen] = useState(false);
  const [suspendOpen, setSuspendOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const apiRef = useGridApiContext();

  const handleReactivate = () => {
    startLoading();

    const body = checkSelection();
    if (body) {
      Axios.put(
        DATABASE_URL + "/user/active/",
        { usersId: body },
        { headers: AuthHeader() }
      ).then(() => {
        setRefresh(true);
        stopLoading();
      }).catch((error) => {
        console.log('Error: ', error);
        stopLoading();
      });
    }
  };

  const handleSuspend = () => {
    startLoading();

    const body = checkSelection();
    if (body) {
      Axios.put(
        DATABASE_URL + "/user/suspend/",
        { usersId: body },
        { headers: AuthHeader() }
      ).then(() => {
        setRefresh(true)
        stopLoading();
      }).catch((error) => {
        console.log('Error: ', error);
        stopLoading();
      });
    }
  };

  const handleDelete = () => {
    startLoading();

    const body = checkSelection();
    if (body) {
      body
        .slice(1)
        .slice(0, -1)
        .split(",")
        .map((id) => (
          Axios.delete(DATABASE_URL + "/user/" + id, {
            headers: AuthHeader(),
          }).then((response) => {
            console.log(response.data);
            stopLoading();
          }).catch((error) => {
            console.log('Error: ', error);
            stopLoading();
          })
        ));
        setRefresh(true);
    }
  };

  const checkSelection = () => {
    const selection = apiRef.current.state.selection;
    if (!selection.length) {
      setDialogMessage("Please selecte at least one user ");
      return false;
    }
    return JSON.stringify(selection);
  };

  return (
    <GridToolbarContainer>
      <div className="tool-bar-container">
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setReactivateOpen(true);
              if (checkSelection()) {
                setDialogMessage(
                  "Are you sure that you want to reactivate the selected users? "
                );
              }
            }}
            className="btn"
          >
            Reactivate
          </Button>
          <ConfirmDialog
            title={dialogMessage}
            open={reactivateOpen}
            setOpen={setReactivateOpen}
            onConfirm={handleReactivate}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSuspendOpen(true);
              if (checkSelection()) {
                setDialogMessage(
                  "Are you sure that you want to suspend the selected users? "
                );
              }
            }}
            className="btn"
          >
            Suspend
          </Button>
          <ConfirmDialog
            title={dialogMessage}
            open={suspendOpen}
            setOpen={setSuspendOpen}
            onConfirm={handleSuspend}
          />
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setDeleteOpen(true);
              if (checkSelection()) {
                setDialogMessage(
                  "Are you sure that you want to delete the selected users? "
                );
              }
            }}
            className="btn"
          >
            Delete
          </Button>
          <ConfirmDialog
            title={dialogMessage}
            open={deleteOpen}
            setOpen={setDeleteOpen}
            onConfirm={handleDelete}
          />
        </div>
        <div>
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </div>
      </div>
    </GridToolbarContainer>
  );
};

function ManageUser() {
  const { user } = useAuthContext();
  const [userList, setUserList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();
  const { isLoading, startLoading, stopLoading } = useLoadingContext();

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 80,
      sortable: false,
      filterable: false,
      headerName: "Edit",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit User"
          onClick={editUser(params.id)}
        />,
      ],
    },
    { field: "first_name", headerName: "First Name", width: 200 },
    { field: "last_name", headerName: "Last Name", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    {field: "test_center_name", headerName: "Test Center", width: 200,
      hide: user.role === 4 ? false : true,
    },
    { field: "role", headerName: "Role", width: 200 },
    { field: "grading_role", headerName: "Grading Role", width: 200, 
      hide: user.role !== 4 ? false : true,
      valueGetter: (params) => params.row.grading_role ? params.row.grading_role : '-----'
    },
    { field: "status", headerName: "Status", width: 100 },
  ];

  const editUser = useCallback(
    (id) => () => {
      navigate("/user/manage-user/" + id);
    },
    []
  );

  useEffect(() => {
    startLoading();

    if (user.role === 4) {
      Axios.get(DATABASE_URL + "/user", {
        headers: AuthHeader(),
      }).then((response) => {
        const userList = response.data.users;
        console.log('user list.....', userList);
        const filteredUserList = userList.filter(
          (item) => item.id !== user.userId && (item.role === 4 || item.role === 1 || item.role === 3)
        );
        filteredUserList.forEach((item) => {
          item.role = ROLE[item.role];
          item.status = STATUS[item.status];
        });
        setUserList(filteredUserList);
        stopLoading();
      });
    }else{
      Axios.get(DATABASE_URL + "/user/test_center/" + user.test_center, {
        headers: AuthHeader(),
      }).then((response) => {
        const userList = response.data.users;
        const filteredUserList = userList.filter(
          (item) => item.id !== user.userId && (item.role === 2 || item.role === 3)
        );
        filteredUserList.forEach((item) => {
          item.role = ROLE[item.role];
          item.status = STATUS[item.status];
        });
        setUserList(filteredUserList);
        stopLoading();
      });
    }
    setRefresh(false)
  }, [refresh]);

  return (
    <div>
      <h1>Manage Users</h1>
      {isLoading ? (
        <div className="loader-display">
          <Loader />
        </div>
      ) : (
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50]}
            pagination
            onCellEditStart={(params, event) => {
              console.log(params.row);
            }}
            onCellEditStop={(params, event) => {
              console.log(params);
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
            componentsProps={{ toolbar: { setRefresh, startLoading, stopLoading } }}
            checkboxSelection
            isRowSelectable={(params) => params.row.id !== user.userId}
            rows={userList}
            columns={columns}
          />
        </div>
      )}
    </div>
  );
}

export default ManageUser;
