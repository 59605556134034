import React, { useEffect, useState, useCallback } from "react";
import { DATABASE_URL } from "../../appConstants";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Button } from "@mui/material";
import Axios from "axios";
import AuthHeader from "../../services/AuthHeader";
import {
  DataGrid,
  GridToolbarContainer,
  useGridApiContext,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { COMPLETED_TEST, STATUS } from "../../appConstants";
import { ConfirmDialog } from "../../components/common";
import EditIcon from "@mui/icons-material/Edit";
import OffSharpIcon from "@mui/icons-material/ToggleOffSharp";
import { useNavigate } from "react-router-dom";
import { USER_ROLES } from "../../appConstants";
import "./Candidate.css"
import { format } from "date-fns-tz";
import AssignExaminerModal from "./AssignExaminerModal";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

const CustomToolbar = ({setRefresh}) => {
  const { user } = useAuthContext();
  const [reactivateOpen, setReactivateOpen] = useState(false);
  const [suspendOpen, setSuspendOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const apiRef = useGridApiContext();

  const handleReactivate = () => {
    const body = checkSelection();
    if (body) {
      Axios.put(
        DATABASE_URL + "/candidate/active/",
        { id: body },
        { headers: AuthHeader() }
      ).then(() => {
        setRefresh(true)
      });
    }
  };

  const handleSuspend = () => {
    const body = checkSelection();
    if (body) {
      Axios.put(
        DATABASE_URL + "/candidate/suspend/",
        { id: body },
        { headers: AuthHeader() }
      ).then(() => {
        setRefresh(true)
      });
    }
  };

  const handleDelete = () => {
    const body = checkSelection();
    if (body) {
      body
        .slice(1)
        .slice(0, -1)
        .split(",")
        .map((id) =>
          Axios.delete(DATABASE_URL + "/candidate/" + id, {
            headers: AuthHeader(),
          }).then((response) => {
            console.log(response.data);
          })
        );
        setRefresh(true)
    }
  };

  const checkSelection = () => {
    const selection = apiRef.current.state.selection;
    if (!selection.length) {
      setDialogMessage("Please selecte at least one candidate ");
      return false;
    }
    return JSON.stringify(selection);
  };

  return (
    <GridToolbarContainer>
      <div className="tool-bar-container">
        <div>
          <Button
            variant="contained"
            color="primary"
            className="btn"
            onClick={() => {
              setReactivateOpen(true);
              if (checkSelection()) {
                setDialogMessage(
                  "Are you sure that you want to reactivate the selected users? "
                );
              }
            }}
          >
            Reactivate
          </Button>
          <ConfirmDialog
            title={dialogMessage}
            open={reactivateOpen}
            setOpen={setReactivateOpen}
            onConfirm={handleReactivate}
          />
          <Button
            variant="outlined"
            color="primary"
            className="btn"
            onClick={() => {
              setSuspendOpen(true);
              if (checkSelection()) {
                setDialogMessage(
                  "Are you sure that you want to suspend the selected users? "
                );
              }
            }}
          >
            Suspend
          </Button>
          <ConfirmDialog
            title={dialogMessage}
            open={suspendOpen}
            setOpen={setSuspendOpen}
            onConfirm={handleSuspend}
          />
          {user.role === USER_ROLES.Administrator ? (
            <Button
              variant="outlined"
              color="error"
              className="btn"
              onClick={() => {
                setDeleteOpen(true);
                if (checkSelection()) {
                  setDialogMessage(
                    "Are you sure that you want to delete the selected users? "
                  );
                }
              }}
            >
              Delete
            </Button>
          ) : (
            ""
          )}
          <ConfirmDialog
            title={dialogMessage}
            open={deleteOpen}
            setOpen={setDeleteOpen}
            onConfirm={handleDelete}
          />
        </div>
        <div>
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </div>
      </div>
    </GridToolbarContainer>
  );
};

function ManageCandidate() {
  const { user } = useAuthContext();
  const [candidateList, setCandidateList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [onOpen, setOnOpen] = useState(false)
  const [receptiveTestAlert, setReceptiveTestAlert] = useState(false)
  const [selectedId, setSelectedId] = useState({
    id: '',
    time_zone: ''
  })
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0);
  const [openAssignExaminerModal, setOpenAssignExaminerModal] = useState(false);
  const [selectedCandidateIdForChangingExaminer, setSelectedCandidateIdForChangingExaminer] = useState('');
  const { isLoading, startLoading, stopLoading } = useLoadingContext();

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 50,
      sortable: false,
      filterable: false,
      headerName: "Edit",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit Candidate"
          onClick={editUser(params.id)}
        />,
      ],
    },
    { field: "first_name", headerName: "First Name", width: 150 },
    { field: "last_name", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "government_id", headerName: "Government ID", width: 200 },
    { field: "completed_test", headerName: "Completed Test", width: 150 },
    { field: "grade", headerName: "Grade", width: 100 },
    { field: "status", headerName: "Status", width: 100 },
    {
      field: "activate",
      type: "actions",
      width: 100,
      sortable: false,
      filterable: false,
      headerName: "Re-Activate",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<OffSharpIcon color={params.row.completed_test === 'Yes' ? 'success' : 'disabled'} />}
          label="Edit Candidate"
          onClick={reActivate(params.id, params.row.type_of_test_id, params.row.time_zone)}
          disabled={params.row.completed_test === 'Yes' ? false : true}
        />,
      ],
    },
    {
      field: "change examiner",
      type: "actions",
      width: 150,
      sortable: false,
      filterable: false,
      headerName: "Change Examiner",
      getActions: (params) => [
        <Button variant="outlined" onClick={changeExaminer(params.id)}>
          Change
        </Button>,
      ],
    },
  ];

  const changeExaminer = useCallback(
    (id) => () => {
      if (!id) {
        console.error('Invalid ID');
        return;
      }
      setOpenAssignExaminerModal(true)
      setSelectedCandidateIdForChangingExaminer(id);
    }
  );
  
  const editUser = useCallback(
    (id) => () => {
      navigate("/candidate/manage-candidate/" + id);
    },
    []
  );

  const reActivate = useCallback(
    (id, type_of_test_id, time_zone) => () => {
      if (type_of_test_id === 3 || type_of_test_id === 4) {
        setReceptiveTestAlert(true)
      }else{
        setOnOpen(true)
        setSelectedId({id: id, time_zone: time_zone})
      }
    }
  )

  const handleReActivate = () => {
    let date_with_timezone = new Date().toLocaleString("en-US", { timeZone: selectedId.time_zone });

    date_with_timezone = format(new Date(date_with_timezone), "yyyy-MM-dd H:mm")
    console.log('time sone formated......', date_with_timezone);
    console.log('selected.....', selectedId);

    Axios.post(DATABASE_URL + "/candidate/re_activate_candidate_test_by_id", 
    {
      id: selectedId.id,
      test_date: date_with_timezone
    },
    {
      headers: AuthHeader(),
    }).then((response) => {
      setRefresh(refresh => !refresh)
    });
  }

  const handleReceptiveTestAlert = () => {
    setReceptiveTestAlert(false)
  }

  const handlePageChange = (params) => {
    const newPage = params >= page ? page + 1 : params < page ? page - 1 : page;
    setPage(newPage);
  }

  useEffect(() => {
    startLoading();

    Axios.get(`${DATABASE_URL}/candidate/test_center/${user.test_center}?page=${page}&pageSize=${pageSize}`, {
      headers: AuthHeader(),
    }).then((response) => {
      console.log(response.data);
      let filteredList = response.data.responseData.data;
      filteredList.forEach((item) => {
        item.status = STATUS[item.status];
        item.completed_test = COMPLETED_TEST[item.completed_test];
      });
      setCandidateList(filteredList);
      setTotalRows(response.data.count);
      stopLoading();
    }).catch((error) => {
      console.log('Error: ', error);
      stopLoading();
    });
    setRefresh(false)
  }, [refresh, page, pageSize]);
  
  return (
    <div>
      <h1>Manage Candidates</h1>
      {isLoading ? (
        <div className="loader-display">
          <Loader />
        </div>
      ) : (
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={(page - 1)}
            onPageChange={(params) => handlePageChange(params)}
            rowsPerPageOptions={[10, 25, 50]}
            pagination
            onCellEditStart={(params, event) => {
              console.log(params.row);
            }}
            onCellEditStop={(params, event) => {
              console.log(params);
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
            componentsProps={{ toolbar: { setRefresh } }}
            checkboxSelection
            isRowSelectable={(params) => params.row.id !== user.userId}
            rows={candidateList}
            columns={columns}
            rowCount={totalRows}
            paginationMode="server"
          />
        </div>
      )}
      <ConfirmDialog
        title="Are you sure? You want to Re-Activate this candidate's test?"
        open={onOpen}
        setOpen={setOnOpen}
        onConfirm={handleReActivate}
        // errorDialog={true}
      />
      <ConfirmDialog
        title="Sorry! Receptive Skill And Demo Test can't be reactivated."
        open={receptiveTestAlert}
        setOpen={setReceptiveTestAlert}
        onConfirm={handleReceptiveTestAlert}
        errorDialog={true}
      />
      <AssignExaminerModal
        openAssignExaminerModal={openAssignExaminerModal}
        setOpenAssignExaminerModal={setOpenAssignExaminerModal}
        selectedCandidateIdForChangingExaminer={selectedCandidateIdForChangingExaminer}
      />
    </div>
  );
}

export default ManageCandidate;
