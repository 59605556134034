const scaleOrder = ['Pre-A1', 'A1.1', 'A1.2', 'A2.1', 'A2.2', 'B1.1', 'B1.2', 'B2.1', 'B2.2', 'C1.1', 'C1.2'];

const getIndex = (value) => {
  const index = scaleOrder.indexOf(value);
  return index !== -1 ? index : scaleOrder.length;
};

const isWithinThreshold = (val1, val2) => {
  const index1 = getIndex(val1);
  const index2 = getIndex(val2);

  const levelDifference = Math.abs(index1 - index2);
  const sublevelDifference = val1 === 'Pre-A1' || val2 === 'Pre-A1' ? 1 : 0;
  const lowDifferenceThreshold = { levelDifference: 1, sublevelDifference };

  return levelDifference <= lowDifferenceThreshold.levelDifference &&
    sublevelDifference <= lowDifferenceThreshold.sublevelDifference;
};

export const useCEFRalert = (value1, value2) => {
  if (!value1 || !value2) {
    return 'Alert: At least one value is null.';
  }

  const status = isWithinThreshold(value1, value2) ? 'success' : 'warning';
  const message = status === 'success' ? 'Success: Values are within an acceptable range.' : 'Warning: There is a huge difference between the results.';

  return { status, message };
};