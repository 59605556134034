import React from 'react'
import { Modal, Box, Typography, Grid } from "@mui/material";
import './TestCenter.css'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: 3
};

function ShowTestCenterDetailsModal({showDetailsModal, setShowDetailsModal, testCenterDetails}) {
    return (
        <Modal
            open={showDetailsModal}
            onClose={() => setShowDetailsModal(!showDetailsModal)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {testCenterDetails.name}
                    {/* {toUpperCase(testCenterDetails.officer_name)} */}
                    <p className='admin-name'>{testCenterDetails.officer_name}</p>
                </Typography>
                <Grid container>
                    <Grid item xs={4}>
                        <div>
                            <h4 className='lable'>Email:</h4>
                            <h3 className='value upper-case'>{testCenterDetails.email}</h3>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div>
                            <h4 className='lable'>Number:</h4>
                            <h3 className='value upper-case'>{testCenterDetails.number}</h3>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div>
                            <h4 className='lable'>Web Page:</h4>
                            <h3 className='value upper-case'>{testCenterDetails.webpage}</h3>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <div>
                            <h4 className='lable'>Examiners:</h4>
                            <h3 className='value'>{testCenterDetails.examiners}</h3>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <h4 className='lable'>Procters:</h4>
                            <h3 className='value'>{testCenterDetails.proctor}</h3>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <div>
                            <h4 className='lable'>Candidates:</h4>
                            <h3 className='value'>{testCenterDetails.candidate}</h3>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <h4 className='lable'>Conducted tests:</h4>
                            <h3 className='value'>{testCenterDetails.conducted_tests}</h3>
                        </div>
                    </Grid>
                </Grid>

                <h3 className='lable licenses'>Licenses:</h3>
                <Grid container>
                    <Grid item xs={4}>
                        <div>
                            <h4 className='lable'>Productive:</h4>
                            <h3 className='value'>{testCenterDetails.productive_skill_test_license}</h3>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div>
                            <h4 className='lable'>Receptive:</h4>
                            <h3 className='value'>{testCenterDetails.receptive_skill_test_license}</h3>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div>
                            <h4 className='lable'>Full Test:</h4>
                            <h3 className='value'>{testCenterDetails.full_test_license}</h3>
                        </div>
                    </Grid>
                </Grid>

                <h3 className='lable licenses'>Address:</h3>
                <Grid container>
                    <Grid item xs={4}>
                        <div>
                            <h4 className='lable'>Address:</h4>
                            <h3 className='value upper-case'>{testCenterDetails.address}</h3>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div>
                            <h4 className='lable'>City:</h4>
                            <h3 className='value upper-case'>{testCenterDetails.city}</h3>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div>
                            <h4 className='lable'>Country:</h4>
                            <h3 className='value upper-case'>{testCenterDetails.country}</h3>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )   
}

export default ShowTestCenterDetailsModal
