import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DATABASE_URL } from "../../appConstants";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import ShowSpeakingResponsesDetails from "./ShowSpeakingResponsesDetails";
import ShowWritingResponsesDetails from "./ShowWritingResponsesDetails";
import { useLoadingContext } from "../../hooks/useLoadingContext";

function ShowGradedResponsesDetails () {
    const params = useParams();
    const [grading, setGrading] = useState()
    const [blobURL, setBlobURL] = useState("");
    const [questionDetails, setQuestionDetails] = useState();
    const { isLoading, startLoading, stopLoading } = useLoadingContext();

    useEffect(() => {
      startLoading();

      Axios.get(DATABASE_URL + "/grading/" + params.id, {
          headers: AuthHeader(),
      }).then((response) => {
          const grading = response.data.grading[0];
          setGrading(grading);
          stopLoading();
      }).catch((error) => {
        console.log('Error: ', error);
        stopLoading();
      });
    }, [params.id]);

    useEffect(() => {
        startLoading();

        if (grading && grading.question_type_id === 2) {
          Axios.get(DATABASE_URL + "/question/speaking/" + grading.question_id + "/group", {
            headers: AuthHeader(),
          }).then((response) => {
            setQuestionDetails(response.data.question[0]);
            fetch(grading.answer_string)
              .then((res) => res.blob())
              .then((blob) => {
                const file = new File([blob], "audio.mp3", { type: "audio/mp3" });
                setBlobURL(URL.createObjectURL(file));
              });
              stopLoading();
          }).catch((error) => {
            console.log('Error: ', error);
            stopLoading();
          });
        }
    
        if (grading && grading.question_type_id !== 2) {
          Axios.get(DATABASE_URL + "/question/writing/" + grading.question_id + "/group", {
            headers: AuthHeader(),
          }).then((response) => {
            setQuestionDetails(response.data.question[0]);
            stopLoading();
          }).catch((error) => {
            console.log('Error: ', error);
            stopLoading();
          });
        }
    }, [grading]);

    return (
        <>
            <div className="grading-answer-wrapper">
                {grading && grading.question_type_id === 2 ? (
                    <ShowSpeakingResponsesDetails
                        questionDetails={questionDetails}
                        answer={blobURL}
                        grading={grading}
                        isLoading={isLoading}
                    />
                ) : (
                ""
                )}
                {grading && grading.question_type_id !== 2 ? (
                  <ShowWritingResponsesDetails 
                    questionDetails={questionDetails}
                    answer={grading.answer_string}
                    grading={grading}
                    isLoading={isLoading}
                  />
                ) : (
                  ""
                )}
            </div>
        </>
    );
}

export default ShowGradedResponsesDetails;