import React, { useState, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import Axios from "axios";
import AuthHeader from "../../services/AuthHeader";
import { DATABASE_URL } from "../../appConstants";
import * as FaIcons from "react-icons/fa"
import ShowTestCenterDetailsModal from "./ShowTestCenterDetailsModal";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useGridApiContext, GridToolbarContainer, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, GridActionsCellItem } from "@mui/x-data-grid";
import { ConfirmDialog } from "../../components/common";
import { USER_ROLES } from "../../appConstants";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

const CustomToolbar = ({setRefresh}) => {
    const { user } = useAuthContext();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [onErrorOpen, setOnErrorOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
  
    const apiRef = useGridApiContext();

    const handleReload = () => {
      setRefresh()
    }
  
    const handleDelete = () => {
      const body = checkSelection();
      if (body) {
        body
          .slice(1)
          .slice(0, -1)
          .split(",")
          .map((id, index) =>
            Axios.delete(DATABASE_URL + "/test_center/" + id, {
              headers: AuthHeader(),
            }).then((response) => {
                if (response.data.response.error) {
                    setErrorMessage(response.data.response.message)
                    setOnErrorOpen(true)
                }else if (response.data.response.success) {
                    setErrorMessage(response.data.response.message)
                    setOnErrorOpen(true)
                }
                console.log(response.data);
            })
          );
        // window.location.reload(false);
      }
    };
  
    const checkSelection = () => {
      const selection = apiRef.current.state.selection;
      if (!selection.length) {
        setDialogMessage("Please selecte at least one test center");
        return false;
      }
      return JSON.stringify(selection);
    };
  
    return (
      <GridToolbarContainer>
        <div className="tool-bar-container">
          <div>
            {user.role === USER_ROLES.Superuser ? (
              <Button
                variant="outlined"
                color="error"
                className="btn"
                onClick={() => {
                  setDeleteOpen(true);
                  if (checkSelection()) {
                    setDialogMessage(
                      "Are you sure that you want to delete the selected test center? "
                    );
                  }
                }}
              >
                Delete
              </Button>
            ) : (
              ""
            )}
            <ConfirmDialog
              title={dialogMessage}
              open={deleteOpen}
              setOpen={setDeleteOpen}
              onConfirm={handleDelete}
            />
            <ConfirmDialog
              title={errorMessage}
              open={onErrorOpen}
              setOpen={setOnErrorOpen}
              onConfirm={handleReload}
              errorDialog={true}
            />
          </div>
          <div>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
          </div>
        </div>
      </GridToolbarContainer>
    );
};

function ManageTestCenters() {
    const [pageSize, setPageSize] = useState(10);
    const [gradingList, setGradingList] = useState([]);
    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const [testCenterDetails, setTestCenterDetails] = useState({})
    const [refresh, setRefresh] = useState(false)
    const navigate = useNavigate()
    const { isLoading, startLoading, stopLoading } = useLoadingContext();

    const columns = [
        {
            field: "edit",
            type: "actions",
            width: 50,
            sortable: false,
            filterable: false,
            headerName: "Edit",
            getActions: (params) => [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit Candidate"
                onClick={editTestCenter(params.row.id)}
              />,
            ],
        },
        { field: "id", headerName: "Center ID", flex: 1 },
        { field: "name", headerName: "Name", flex: 1 },
        { field: "officer_name", headerName: "Officer Name", flex: 1 },
        { field: "productive_skill_test_license", headerName: "Productive Skill Test License", flex: 1 },
        { field: "receptive_skill_test_license", headerName: "Receptive Skill Test License", flex: 1 },
        { field: "full_test_license", headerName: "Full Test License", flex: 1 },
        {
            field: "view",
            type: "actions",
            flex: 1,
            sortable: false,
            filterable: false,
            headerName: "View Details",
            getActions: (params) => [
              <Button onClick={viewDetails(params.id)}>
                <FaIcons.FaEye />
              </Button>,
            ],
          },
        {
            field: "actions",
            type: "actions",
            flex: 1,
            sortable: false,
            filterable: false,
            headerName: "Licenses",
            getActions: (params) => [
              <Button onClick={viewLicenses(params.id)}>
                Licenses
              </Button>,
            ],
        },
    ];

    const viewDetails = useCallback(
        (id) => () => {
            Axios.get(DATABASE_URL + "/test_center/" + id, {
                headers: AuthHeader(),
            }).then((response) => {
                setTestCenterDetails(response.data.testCenter[0])
            });
            setShowDetailsModal(!showDetailsModal)
        },
        []
    );

    const editTestCenter = useCallback(
        (id) => () => {
            navigate("/test-centers/manage-test-center/" + id);
        },
        []
    );

    const viewLicenses = useCallback(
        (id) => () => {
            navigate('/test-centers/test-center-licenses/'+id);
        },
        []
    );

    useEffect(() => {
        startLoading();

        Axios.get(DATABASE_URL + "/test_center", {
            headers: AuthHeader(),
        }).then((response) => {
            setGradingList(response.data.testCenter);
            stopLoading();
        }).catch((error) => {
          console.log('Error: ', error);
          stopLoading();
        });
        setRefresh(false)
    }, [refresh])
    
    return (
        <>
            <h1>Manage Test centers</h1>
            <div style={{ height: 600, width: "100%" }}>
                {isLoading ? (
                  <div className="loader-display">
                    <Loader />
                  </div>
                ) : (
                  <DataGrid
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 25, 50]}
                    pagination
                    disableColumnMenu
                    initialState={{
                        sorting: {
                        sortModel: [{ field: "id", sort: "asc" }],
                        },
                    }}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{ toolbar: { setRefresh } }}
                    checkboxSelection
                    rows={gradingList}
                    columns={columns}
                  />
                )}
            </div>
            <ShowTestCenterDetailsModal 
                showDetailsModal={showDetailsModal} 
                setShowDetailsModal={setShowDetailsModal} 
                testCenterDetails={testCenterDetails}
            />
        </>
    )
}

export default ManageTestCenters