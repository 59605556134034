import React, { useState, useEffect } from "react"
import { 
    DataGrid
} from "@mui/x-data-grid"
import Axios from "axios"
import AuthHeader from "../../services/AuthHeader"
import { DATABASE_URL } from "../../appConstants"
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";


const ManageQuestionGroup = () => {
    const [pageSize, setPageSize] = useState(10);
    const [questionGroupList, setquestionGroupList] = useState([]);
    const { isLoading, startLoading, stopLoading } = useLoadingContext();

    const columns = [
        { field: "number", headerName: "Group Number", flex: 1 },
        { field: "speaking_question_count", headerName: "Speaking Qestions Count", flex: 1 },
        { field: "writing_question_count", headerName: "Writing Qestions Count", flex: 1 },
    ];

    useEffect(() => {
        startLoading();

        Axios.get(DATABASE_URL + "/question/get_all_question_groups", {
            headers: AuthHeader(),
        }).then((response) => {
            setquestionGroupList(response.data.question_groups);
            stopLoading();
        }).catch((error) => {
            console.log('Error: ', error);
            stopLoading();
        });
    }, [])

    return (
        <>
            <h1>
                Manage Question Groups
            </h1>
            <div style={{ height: 600, width: "100%" }}>
                {isLoading ? (
                    <div className="loader-display">
                        <Loader />
                    </div>
                ) : (
                    <DataGrid
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 25, 50]}
                        pagination
                        disableColumnMenu
                        initialState={{
                            sorting: {
                            sortModel: [{ field: "number", sort: "asc" }],
                            },
                        }}
                        rows={questionGroupList}
                        columns={columns}
                    />
                )}
            </div>
        </>
    )
}

export default ManageQuestionGroup