import React, {useState, useEffect} from "react";
import { Grid, Button, Alert, Stack, MenuItem } from "@mui/material";
import {
  TextField,
  makeValidate,
  makeRequired,
  Select
} from "mui-rff";
import EditIcon from "@mui/icons-material/Edit";
import * as Yup from "yup";
import { Form } from "react-final-form";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";
import { useNavigate, useParams } from "react-router-dom";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

function UpdateWritingQuestion() {
    const params = useParams();
    const [message, setMessage] = useState();
    const [isDisabled, setIsDisabled] = useState(true);
    const [question, setQuestion] = useState(null)
    const navigate = useNavigate()
    const [questionGroups, setQuestionGroups] = useState([])
    const { isLoading, startLoading, stopLoading } = useLoadingContext();

    useEffect(() => {
        startLoading();

        Axios.get(DATABASE_URL + "/question/get_question_groups/", {
            headers: AuthHeader(),
        }).then((response) => {
            console.log(response.data.question_groups);
            let groups = response.data.question_groups
            setQuestionGroups(groups.filter(g => g.id === 5 || g.id === 6))
            stopLoading();
        }).catch((error) => {
            console.log('Error: ', error);
            stopLoading();
        });
    }, [])

    useEffect(() => {
        startLoading();

        Axios.get(
            DATABASE_URL + "/question/writing/" + params.id + '/group',
            { headers: AuthHeader() }
        )
        .then((response) => {
            console.log('writing question.......', response.data);
            let q = response.data.question[0]
            setQuestion(q)
            stopLoading();
        })
        .catch((error) => {
            console.log(error.response);
            stopLoading();
        });
    }, [])

    const schema = Yup.object().shape({
        name: Yup.string().required("Question name is required"),
        description: Yup.string().required("Question Description is required"),
        time: Yup.string().required("Question time is required"),
        word_limit: Yup.string().required("Words Limit is required"),
        word_limit_in_char: Yup.string().required("Words Limit Charater is required"),
        question_group_id: Yup.number().required("Question Group is required")
    });

    const validate = makeValidate(schema);

    const required = makeRequired(schema);

    const enableEdit = () => {
        setIsDisabled(false);
    };

    const writingQuesFormFields = [
        <TextField
            label="Question Title"
            name="name"
            placeholder="Question Titile"
            type="text"
            required={required.name}
            disabled={isDisabled}
        />,
        <TextField
            label="Question Description"
            name="description"
            id="outlined-textarea"
            placeholder="Question Description"
            type="text"
            required={required.description}
            disabled={isDisabled}
            multiline
            rows={3}
        />,
        <Grid container direction="row" spacing={4}>
            <Grid item xs={6}>
                <TextField
                    label="Question Time"
                    name="time"
                    placeholder="Question Time"
                    type="number"
                    required={required.time}
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Words Limit e.g:(80 to 100)"
                    name="word_limit"
                    placeholder="Words Limit"
                    type="text"
                    required={required.word_limit}
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Words Limit in Characters"
                    name="word_limit_in_char"
                    placeholder="Words Limit in Characters"
                    type="number"
                    required={required.word_limit_in_char}
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={6}>
                <Select
                    label="Question Group"
                    name="question_group_id"
                    placeholder="Question Group"
                    required={required.question_group_id}
                    disabled={isDisabled}
                >
                    {questionGroups.map((obj, index) => (
                        <MenuItem value={obj.id} key={index}>
                            {obj.number}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </Grid>,
    ];

    const onSubmit = async (values) => {
        startLoading();
        setMessage("");

        Axios.put(
            DATABASE_URL + "/question/update_writing_question/"+ params.id,
            { ...values },
            { headers: AuthHeader() }
        )
        .then((response) => {
            setMessage("Question Updated!");
            console.log(response.data);
            navigate("/questions/writing-questions");
            stopLoading();
        })
        .catch((error) => {
            console.log(error.response);
            stopLoading();
        });
    };

    const onCancel = () => {
        navigate("/questions/writing-questions");
    };

    return (
        <div>
            <div className="title-with-button">
                <h1>Update Speaking Question</h1>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<EditIcon />}
                    onClick={enableEdit}
                    disabled={!isDisabled}
                >
                    Enable Edit
                </Button>
            </div>
            {isLoading ? (
                <div className="loader-display">
                    <Loader />
                </div>
            ) : (
                <Stack spacing={2}>
                    <Form
                        onSubmit={onSubmit}
                        validate={validate}
                        initialValues={question}
                        render={({
                            hasSubmitErrors,
                            handleSubmit,
                            submitting,
                            form,
                            values,
                        }) => (
                            <form onSubmit={handleSubmit} className="add-user-form">
                                <Grid container direction="row" spacing={4}>
                                    {writingQuesFormFields.map((field, index) => (
                                    <Grid item xs={12} key={index}>
                                        {field}
                                    </Grid>
                                    ))}
                                </Grid>
                                <Grid container spacing={4} className="form-button-group">
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            size="large"
                                            disabled={submitting || isDisabled}
                                        >
                                            Update
                                        </Button>
                                        </Grid>
                                        <Grid item>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            type="submit"
                                            size="large"
                                            disabled={submitting}
                                            onClick={onCancel}
                                        >
                                            BACK
                                        </Button>
                                    </Grid>
                                </Grid>
                                {hasSubmitErrors && <Alert severity="error">error</Alert>}
                                {/* <pre>{JSON.stringify(values)}</pre> */}
                            </form>
                        )}
                    />
                    {message && <Alert severity="success">{message}</Alert>}
                </Stack>
            )}
        </div>
    )
}

export default UpdateWritingQuestion
