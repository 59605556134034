import React from "react";
import { Grid, Button, Alert } from "@mui/material";
import { Form } from "react-final-form";
import {
  TextField,
  Select,
  Checkboxes,
  makeValidate,
  makeRequired,
} from "mui-rff";
import { ROLE, USER_ROLES, GRADING_ROLE } from "../../appConstants";
import MenuItem from "@mui/material/MenuItem";
import * as Yup from "yup";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthHeader from "../../services/AuthHeader";
import { Loader } from "../common";
import { useAuthContext } from "../../hooks/useAuthContext";

const UserForm = (props) => {
  const { initialValues, apiURL, submitNav, cancelNav, isDisabled, isLoading, startLoading, stopLoading } = props;
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const schema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string().email().required("Email is required"),
    role: Yup.string().required(),
    grading_role: Yup.string().required(),
    change_pass: Yup.boolean(),
    new_pass: Yup.string()
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,24}$/,
        "Password must include at least one lower case, one upper case, and a number. "
      )
      .when("change_pass", {
        is: true,
        then: Yup.string()
          .min(8, "Minimum of 8 characters in a password")
          .max(24, "Maximum of 24 characters in a password")
          .required("Must enter new password"),
      }),
    retype_pass: Yup.string().when("change_pass", {
      is: true,
      then: Yup.string()
        .required("Must retype new password")
        .oneOf([Yup.ref("new_pass"), null], "Passwords must match"),
    }),
  });

  const validate = makeValidate(schema);

  const required = makeRequired(schema);

  const formFields = [
    <TextField
      label="First Name"
      name="first_name"
      placeholder="First Name"
      type="text"
      disabled={isDisabled}
      required={required.first_name}
    />,
    <TextField
      label="Last Name"
      name="last_name"
      placeholder="Last Name"
      type="text"
      disabled={isDisabled}
      required={required.last_name}
    />,
    <TextField
      label="Email"
      name="email"
      placeholder="Email"
      type="email"
      disabled={isDisabled}
      required={required.email}
    />,
    <Select
      label="Role"
      name="role"
      placeholder="Role"
      disabled={isDisabled || user.role !== USER_ROLES.Administrator}
    >
      {ROLE.map((o, idx) => (
        <MenuItem value={idx} key={idx}>
          {o}
        </MenuItem>
      ))}
    </Select>,
    <Select
      label="Grading Role"
      name="grading_role"
      placeholder="Grading Role"
      disabled={isDisabled || user.role !== USER_ROLES.Administrator}
    >
      {GRADING_ROLE.map((o, idx) => (
        <MenuItem value={o} key={idx}>
          {o}
        </MenuItem>
      ))}
    </Select>,
    <TextField
      label="Password"
      name="new_pass"
      placeholder="Password"
      type="text"
      disabled={isDisabled}
      helperText="The password will change only if you check set new password below"
    />,
    <TextField
      label="Retype Password"
      name="retype_pass"
      placeholder="Retype Password"
      type="text"
      disabled={isDisabled}
    />,
    <Checkboxes
      name="change_pass"
      required={required.change_pass}
      disabled={isDisabled}
      data={{
        label: "Set new password?",
        value: false,
      }}
    />,
  ];

  const onSubmit = async (values) => {
    startLoading();
    const password = values.change_pass ? values.new_pass : values.password;

    const newUser = {
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      role: values.role,
      password: password,
      status: values.status,
      test_center: values.test_center,
      grading_role: values.grading_role,
    };

    Axios.put(apiURL, { ...newUser }, { headers: AuthHeader() })
      .then((response) => {
        stopLoading();
        navigate(submitNav);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        stopLoading();
      });
  };
  const onCancel = () => {
    navigate(cancelNav);
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-display">
          <Loader />
        </div>
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validate}
          render={({ hasSubmitErrors, handleSubmit, submitting, form, values }) => (
            <form onSubmit={handleSubmit} className="test-center-form">
              <Grid container direction="row" spacing={4}>
                {formFields.map((field, index) => (
                  <Grid item xs={6} key={index}>
                    {field}
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={4} className="form-button-group" justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={submitting || isDisabled}
                  >
                    UPDATE
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={submitting}
                    onClick={onCancel}
                  >
                    CANCEL
                  </Button>
                </Grid>
              </Grid>
              {hasSubmitErrors && <Alert severity="error">error</Alert>}
              {/* <pre>{JSON.stringify(values)}</pre> */}
            </form>
          )}
        />
      )}
    </>
  );
};

export default UserForm;
