import React from "react";
import { SecondaryNavbar } from "../../components/common";
import { Outlet, NavLink } from "react-router-dom";
import * as FaIcons from "react-icons/fa";

import { useAuthContext } from "../../hooks/useAuthContext";
import { USER_ROLES } from "../../appConstants";

const addCandidate = <NavLink to="add-candidate"> <FaIcons.FaUserPlus className="fa-i" /> Add Candidate</NavLink>;
const manageCandidate = (
  <NavLink to="manage-candidate"> <FaIcons.FaUsersCog className="fa-i" /> Manage Candidates</NavLink>
);

function Candidates() {
  const { user } = useAuthContext();

  return (
    <div className="page-content-container">
      {user.role === USER_ROLES.Administrator ? (
        <SecondaryNavbar menu={[addCandidate, manageCandidate]} />
      ) : (
        <SecondaryNavbar menu={[manageCandidate]} />
      )}

      <div className="page-content">
        <Outlet />
      </div>
    </div>
  );
}

export default Candidates;
