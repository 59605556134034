import React, { useState, useEffect } from "react";
import { Grid, Button, Alert, Stack } from "@mui/material";
import {
  TextField,
  makeValidate,
  makeRequired
} from "mui-rff";
import { DataGrid } from "@mui/x-data-grid";
import * as Yup from "yup";
import { Form } from "react-final-form";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";
import { useParams } from "react-router-dom";
import { format } from "date-fns-tz";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";


function TestCenterLicenses() {
    const [message, setMessage] = useState();
    const [errMessage, setErrMessage] = useState();
    const [testCenter, setTestCenter] = useState({})
    const params = useParams()
    const [isDisabled, setIsDisabled] = useState(true);
    const [refresh, setRefresh] = useState(false)
    const { isLoading, startLoading, stopLoading } = useLoadingContext();

    const [pageSize, setPageSize] = useState(10);
    const [testCenterLicenseHistory, setTestCenterLicenseHistory] = useState([]);

    const columns = [
        { field: "test_center_name", headerName: "Test Center", flex: 1 },
        { field: "productive_skill_test_license", headerName: "Productive Skill Test License", flex: 1 },
        { field: "receptive_skill_test_license", headerName: "Receptive Skill Test License", flex: 1 },
        { field: "full_test_license", headerName: "Full Test License", flex: 1 },
        { field: "created_at", headerName: "Date", flex: 1 },
    ];

    useEffect(() => {
        startLoading();

        Axios.get(DATABASE_URL + "/test_center/get_license_history/"+params.id, {
            headers: AuthHeader(),
        }).then((response) => {
            let history = response.data.testCenterLicenseHistory
            history.forEach((item) => {
                item.created_at = format(new Date(item.created_at), "dd/MM/yyyy")
            })
            setTestCenterLicenseHistory(history);
            stopLoading();
        }).catch((error) => {
            console.log('Error: ', error);
            stopLoading();
        });
    }, [refresh])

    const schema = Yup.object().shape({
        productive_skill_test_license: Yup.number(),
        receptive_skill_test_license: Yup.number(),
        full_test_license: Yup.number(),
    });

    const validate = makeValidate(schema);

    const required = makeRequired(schema);

    useEffect(() => {
        startLoading();

        Axios.get(
          DATABASE_URL + "/test_center/"+params.id,
          { headers: AuthHeader() }
        )
          .then((response) => {
            setTestCenter(response.data.testCenter[0])
            console.log(response.data.testCenter);
            stopLoading();
          })
          .catch((error) => {
            setErrMessage("Something wrong!");
            console.log(error.response.data.message);
            stopLoading();
          });
    }, [])

    const speakingQuesFormFields = [
        <Grid container direction="row" spacing={4}>
            <Grid item xs={4}>
                <TextField
                    label="Productive Skill Test License"
                    name="productive_skill_test_license"
                    placeholder="Productive Skill Test License"
                    type="number"
                    disabled={isDisabled}
                    required={required.productive_skill_test_license}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Receptive Skill Test License"
                    name="receptive_skill_test_license"
                    placeholder="Receptive Skill Test License"
                    type="number"
                    disabled={isDisabled}
                    required={required.receptive_skill_test_license}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Full Test License"
                    name="full_test_license"
                    placeholder="Full Test License"
                    type="number"
                    disabled={isDisabled}
                    required={required.full_test_license}
                />
            </Grid>
        </Grid>
    ];

    const onSubmit = async (values) => {
        startLoading();
        setMessage("");
        setErrMessage("");
        values['test_center_id'] = params.id

        Axios.post(
            DATABASE_URL + "/test_center/add_licenses",
            { ...values },
            { headers: AuthHeader() }
        )
        .then((response) => {
            setMessage("New Licenses are added!");
            console.log(response.data);
            stopLoading();
            setRefresh(!refresh)
        })
        .catch((error) => {
            setErrMessage("Something wrong!");
            console.log(error.response.data.message);
            stopLoading();
        });
    };


    return (
        <div>
            <h1>Add New License to {testCenter.name}</h1>
            <Button
                variant="outlined"
                color="primary"
                type="submit"
                size="large"
                disabled={!isDisabled}
                style={{ marginBottom: 20 }}
                onClick={() => setIsDisabled(!isDisabled)}
            >
                Enable Form
            </Button>
            <Stack spacing={2}>
                <Form
                onSubmit={onSubmit}
                validate={validate}
                render={({
                    hasSubmitErrors,
                    handleSubmit,
                    submitting,
                    form,
                    values,
                }) => (
                    <form onSubmit={handleSubmit} className="add-user-form">
                        <Grid container direction="row" spacing={4}>
                            {speakingQuesFormFields.map((field, index) => (
                            <Grid item xs={12} key={index}>
                                {field}
                            </Grid>
                            ))}
                        </Grid>
                        <Grid container spacing={4} className="form-button-group" justifyContent="flex-end">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="large"
                                    disabled={submitting || isDisabled}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                        {hasSubmitErrors && <Alert severity="error">error</Alert>}
                        {/* <pre>{JSON.stringify(values)}</pre> */}
                    </form>
                )}
                />
                {message && <Alert severity="success">{message}</Alert>}
                {errMessage && <Alert severity="warning">{errMessage}</Alert>}
            </Stack>

            <>
                <h1>Licenses History</h1>
                <div style={{ height: 600, width: "100%" }}>
                    {isLoading ? (
                        <div className="loader-display">
                            <Loader />
                        </div>
                    ) : (
                        <DataGrid
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 25, 50]}
                            pagination
                            disableColumnMenu
                            initialState={{
                                sorting: {
                                sortModel: [{ field: "deadline", sort: "asc" }],
                                },
                            }}
                            rows={testCenterLicenseHistory}
                            columns={columns}
                        />
                    )}
                </div>
            </>
        </div>
    )
}

export default TestCenterLicenses
