export const useCEFRconverter = (grade) => {
  const gradeRanges = [
    { max: 24, value: 'Pre-A1' },
    { max: 38, value: 'A1.1' },
    { max: 44, value: 'A1.2' },
    { max: 50, value: 'A2.1' },
    { max: 56, value: 'A2.2' },
    { max: 61, value: 'B1.1' },
    { max: 67, value: 'B1.2' },
    { max: 74, value: 'B1.3' },
    { max: 81, value: 'B2.1' },
    { max: 91, value: 'B2.2' },
    { max: 100, value: 'B2.3' }
  ];

  if (typeof grade !== 'number' || isNaN(grade) || grade < 0) {
    return '';  // handle invalid or unexpected input
  }

  // Use destructuring to immediately return the value field if found
  const { value = '' } = gradeRanges.find(({ max }) => grade <= max) || {};
  return value;
};
