import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { DATABASE_URL } from "../appConstants";
import axios from "axios";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const login = async (email, password) => {
    console.log('DATABASE_URL',DATABASE_URL)
    setIsLoading(true);
    setError(null);

    axios
      .post(DATABASE_URL + "/auth", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          // save the user to local storage
          const item = {
            ...response.data,
            expiry: Date.now() + 24 * 60 * 60 * 1000,
          };
          localStorage.setItem("currentUser", JSON.stringify(item));

          // update the auth context
          dispatch({ type: "LOGIN", payload: response.data });

          // update loading state
          setIsLoading(false);
        }
      })
      .catch((error) => {
        const status = error.response.status;
        setIsLoading(false);
        if (status === 0) {
          setError("No Server Response");
        } else if (status === 401) {
          setError("Wrong username or password");
        } else {
          setError("Something wrong");
        }

        // if (error.response.data.error) {
        //   setError(error.response.data.error);
        // }
      });
  };

  return { login, isLoading, error };
};
