import React, { useEffect } from "react";
import { Button } from "@mui/material";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";
import { useState } from "react";
import AuthHeader from "../../services/AuthHeader";
import { useAuthContext } from "../../hooks/useAuthContext";
import { UserForm } from "../../components/forms";
import EditIcon from "@mui/icons-material/Edit";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

import "./Profile.css";

export default function UserDetails() {
  const { user } = useAuthContext();
  const [userDetails, setUserDetails] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const { isLoading, startLoading, stopLoading } = useLoadingContext();

  useEffect(() => {
    startLoading();

    Axios.get(DATABASE_URL + "/user/" + user.userId, {
        headers: AuthHeader(),
      }).then((response) => {
        const user = response.data.user;
        setUserDetails(...user);
        stopLoading();
      }).catch((error) => {
        console.log(error);
        stopLoading();
      });    
  }, []);

  const enableEdit = () => {
    setIsDisabled(false);
  };

  return (
    <div>
      <div className="title-with-button">
        <h1>User Details</h1>
        <Button
          variant="outlined"
          size="large"
          startIcon={<EditIcon />}
          onClick={enableEdit}
        >
          Enable Edit
        </Button>
      </div>
      {isLoading ? (
          <div className="loader-display">
            <Loader />
          </div>
        ) : (
          <UserForm
            initialValues={userDetails}
            apiURL={DATABASE_URL + "/user/" + user.userId}
            submitNav={"/"}
            cancelNav={"/"}
            isDisabled={isDisabled}
            isLoading={isLoading}
            startLoading={startLoading}
            stopLoading={stopLoading}
          />
      )}
    </div>
  );
}
