import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";
import GradingSpeaking from "./GradingSpeaking";
import GradingWriting from "./GradingWriting";
import { useLoadingContext } from "../../hooks/useLoadingContext";

import "./Grading.css";

function UpdateGrading() {
  const params = useParams();
  const [grading, setGrading] = useState();
  const [blobURL, setBlobURL] = useState("");
  const [questionDetails, setQuestionDetails] = useState();
  const { isLoading, startLoading, stopLoading } = useLoadingContext();

  useEffect(() => {
    startLoading();

    Axios.get(DATABASE_URL + "/grading/" + params.id, {
      headers: AuthHeader(),
    }).then((response) => {
      const grading = response.data.grading[0];
      console.log(grading);
      setGrading(grading);
      stopLoading();
    }).catch((error) => {
      console.log('Error: ', error);
      stopLoading();
    });
  }, [params.id]);

  useEffect(() => {
    startLoading ();
    
    if (grading && grading.question_type_id === 2) {
      Axios.get(DATABASE_URL + "/question/speaking/" + grading.question_id + "/group", {
        headers: AuthHeader(),
      }).then((response) => {
        setQuestionDetails(response.data.question[0]);
        fetch(grading.answer_string)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "audio.mp3", { type: "audio/mp3" });
            setBlobURL(URL.createObjectURL(file));
          });
          stopLoading();
      }).catch((error) => {
        console.log('Error: ', error);
        stopLoading();
      });
    }

    if (grading && grading.question_type_id === 3) {
      Axios.get(DATABASE_URL + "/question/writing/" + grading.question_id + "/group", {
        headers: AuthHeader(),
      }).then((response) => {
        setQuestionDetails(response.data.question[0]);
        stopLoading();
      }).catch((error) => {
        console.log('Error: ', error);
        stopLoading();
      });
    }
  }, [grading]);

  return (
    <>
      <h1>
        {grading && grading.question_type_id === 2 ? "Speaking" : "Writing"}{" "}
        Test - Task {grading && grading.question_id}
      </h1>
      <div className="grading-answer-wrapper">
          {grading && grading.question_type_id === 2 ? (
            <GradingSpeaking
              candidate_id={grading.candidate_id}
              questionDetails={questionDetails}
              answer={blobURL}
              isLoading={isLoading}
              startLoading={startLoading}
              stopLoading={stopLoading}
            />
          ) : (
            ""
          )}
          {grading && grading.question_type_id === 3 ? (
            <GradingWriting
              candidate_id={grading.candidate_id}
              questionDetails={questionDetails}
              answer={grading.answer_string}
              isLoading={isLoading}
              startLoading={startLoading}
              stopLoading={stopLoading}
            />
          ) : (
            ""
          )}
      </div>
    </>
  );
}

export default UpdateGrading;
