import React from "react";
import { Outlet } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";

const RequireAuth = ({ allowedRoles }) => {
  const { user } = useAuthContext();
  console.log('check roles............',allowedRoles );
  if (allowedRoles.includes(user.role)) {
    return <Outlet />;
  } else {
    return <h1>Not allow to view the page</h1>;
  }
};

export default RequireAuth;
