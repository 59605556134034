import React, {useState} from "react";
import { Grid, Button, Alert, Stack } from "@mui/material";
import {
  TextField,
  makeValidate,
  makeRequired,
} from "mui-rff";
import * as Yup from "yup";
import { Form } from "react-final-form";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

function AddTestCenter() {
    const [message, setMessage] = useState();
    const [errMessage, setErrMessage] = useState();
    const { isLoading, startLoading, stopLoading } = useLoadingContext();

    const schema = Yup.object().shape({
        name: Yup.string().required("Test Center name is required"),
        address: Yup.string().required("Test Center address is required"),
        country: Yup.string().required("Country is required"),
        city: Yup.string().required("City is required"),
        officer_name: Yup.string().required("Officer Name is required"),
        number: Yup.string().required("Office Number is required"),
        email: Yup.string().required("Email is required"),
        webpage: Yup.string().required("Web page is required"),
        productive_skill_test_license: Yup.string().required("Productive Skill Test License"),
        receptive_skill_test_license: Yup.string().required("Receptive Skill Test License"),
        full_test_license: Yup.string().required("Full Test License"),
    });

    const validate = makeValidate(schema);

    const required = makeRequired(schema);

    const speakingQuesFormFields = [
        <TextField
            label="Test Center Name"
            name="name"
            placeholder="Test Center Name"
            type="text"
            required={required.name}
        />,
        <TextField
            label="Address"
            name="address"
            placeholder="Adress"
            type="text"
            required={required.address}
        />,
        <TextField
            label="Country"
            name="country"
            placeholder="Country"
            type="text"
            required={required.country}
        />,
        <TextField
            label="City"
            name="city"
            placeholder="City"
            type="text"
            required={required.city}
        />,
        <TextField
            label="Officer Name"
            name="officer_name"
            placeholder="Officer Name"
            type="text"
            required={required.officer_name}
        />,
        <TextField
            label="Number"
            name="number"
            placeholder="Number"
            type="text"
            required={required.number}
        />,
        <TextField
            label="Email"
            name="email"
            placeholder="Email"
            type="email"
            required={required.email}
        />,
        <TextField
            label="Web Page"
            name="webpage"
            placeholder="Web Page"
            type="text"
            required={required.webpage}
        />,
        <TextField
            label="Productive Skill Test License"
            name="productive_skill_test_license"
            placeholder="Productive Skill Test License"
            type="number"
            required={required.productive_skill_test_license}
        />,
        <TextField
            label="Receptive Skill Test License"
            name="receptive_skill_test_license"
            placeholder="Receptive Skill Test License"
            type="number"
            required={required.receptive_skill_test_license}
        />,
        <TextField
            label="Full Test License"
            name="full_test_license"
            placeholder="Full Test License"
            type="number"
            required={required.full_test_license}
        />,
    ];

    const onSubmit = async (values) => {
        startLoading();
        setMessage("");
        setErrMessage("");
        console.log('all values..........', values);

        Axios.post(
            DATABASE_URL + "/test_center",
            { ...values },
            { headers: AuthHeader() }
        )
        .then((response) => {
            setMessage("New Test Center Created!");
            console.log(response.data);
            stopLoading();
        })
        .catch((error) => {
            setErrMessage("Something wrong!");
            console.log(error.response.data.message);
            stopLoading();
        });
    };

    return(
        <div>
            <h1>Add New Test Center</h1>
            <Stack spacing={2}>
                {isLoading ? (
                    <div className="loader-display">
                        <Loader />
                    </div>
                ) : (
                    <>
                        {message && <Alert severity="success">{message}</Alert>}
                        {errMessage && <Alert severity="warning">{errMessage}</Alert>}
                        <Form
                            onSubmit={onSubmit}
                            validate={validate}
                            render={({
                                hasSubmitErrors,
                                handleSubmit,
                                submitting,
                                form,
                                values,
                            }) => (
                                <form onSubmit={handleSubmit} className="add-user-form">
                                    <Grid container direction="row" spacing={4}>
                                        {speakingQuesFormFields.map((field, index) => (
                                        <Grid item xs={6} key={index}>
                                            {field}
                                        </Grid>
                                        ))}
                                    </Grid>
                                    <Grid container spacing={4} className="form-button-group" justifyContent="flex-end">
                                        <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            size="large"
                                            disabled={submitting}
                                        >
                                            CREATE
                                        </Button>
                                        </Grid>
                                    </Grid>
                                    {hasSubmitErrors && <Alert severity="error">error</Alert>}
                                    {/* <pre>{JSON.stringify(values)}</pre> */}
                                </form>
                            )}
                        />
                    </>
                )}
            </Stack>
        </div>
    )
}

export default AddTestCenter