import React from "react";
import "./App.css";
import { Header, Navbar, RequireAuth } from "./components/common";
import * as Page from "./pages";
import { Route, Routes, Navigate } from "react-router-dom";
import { USER_ROLES } from "./appConstants";
import { useAuthContext } from "./hooks/useAuthContext";

function App() {
  const { user } = useAuthContext();

  if (!user) {
    return <Page.Login />;
  }

  return (
    <div className="App">
      <Header />
      <div className="main-container">
        <div className="side-navbar">
          <Navbar />
        </div>
        <Routes>
          <Route
            path="login"
            element={!user ? <Page.Login /> : <Navigate to="/" />}
          />
          <Route index element={<Page.Home />} />
          <Route path="reports" element={<Page.Reports />}>
            <Route path="download-report" element={<Page.DownloadReport />} />
            <Route path="forms" element={<Page.Forms />} />
          </Route>
          <Route path="profile" element={<Page.Profile />}>
            <Route path="user-details" element={<Page.UserDetails />} />
            <Route
              element={
                <RequireAuth
                  allowedRoles={[USER_ROLES.Administrator, USER_ROLES.Proctor, USER_ROLES.Superuser]}
                />
              }
            >
              <Route
                path="test-center-details"
                element={<Page.TestCenterDetails />}
              />
            </Route>
          </Route>

          <Route path="user" element={<Page.User />}>
            <Route
              element={
                <RequireAuth allowedRoles={[USER_ROLES.Administrator, USER_ROLES.Superuser]} />
              }
            >
              <Route path="add-user" element={<Page.AddUser />} />
              <Route path="manage-user" element={<Page.ManageUser />} />
              <Route path="manage-user/:id" element={<Page.UpdateUser />} />
              <Route path="add-administrator" element={<Page.AddAministrator />} />
            </Route>
          </Route>
          <Route path="licenses" element={<Page.Licenses />} />
          <Route path="candidate" element={<Page.Candidate />}>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[USER_ROLES.Administrator, USER_ROLES.Proctor]}
                />
              }
            >
              <Route path="add-candidate" element={<Page.AddCandidate />} />
              <Route
                path="manage-candidate"
                element={<Page.ManageCandidate />}
              />
              <Route
                path="manage-candidate/:id"
                element={<Page.UpdateCandidate />}
              />
              <Route path="certificate/:id" element={<Page.Certificate />} />
            </Route>
          </Route>
          <Route path="groups" element={<Page.Group />}>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[USER_ROLES.Administrator, USER_ROLES.Proctor]}
                />
              }
            >
              <Route path="add-group" element={<Page.AddGroup />} />
              <Route path="manage-groups" element={<Page.ManageGroup />} />
              <Route path="manage-groups/:id" element={<Page.UpdateGroup />} />
            </Route>
          </Route>
          <Route path="grading" element={<Page.Grading />}>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[USER_ROLES.Administrator, USER_ROLES.Examiner]}
                />
              }
            >
              <Route
                path="pending-for-grading"
                element={<Page.PendingForGrading />}
              />
              <Route
                path="pending-for-grading/:id"
                element={<Page.UpdateGrading />}
              />
              <Route
                path="graded"
                element={<Page.Graded />}
              />
              <Route 
                path="graded/:id"
                element={<Page.ShowGradedResponsesDetails />}
              />
              <Route path="criteria" element={<Page.Criteria />} />
            </Route>
          </Route>
          <Route path="questions" element={<Page.Questions />}>
            <Route path="add-questions" element={<Page.AddQuestions />} />
            <Route path="add-question-group" element={<Page.AddQuestionGroup />} />
            <Route path="manage-question-group" element={<Page.ManageQuestionGroup />} />
            <Route path="manage-question-group/:id" element={<Page.UpdateQuestionGroup />} />
            <Route path="speaking-questions" element={<Page.SpeakingQuestions />} />
            <Route path="speaking-questions/:id" element={<Page.UpdateSpeakingQuestion />} />
            <Route path="writing-questions" element={<Page.WritingQuestions />} />
            <Route path="writing-questions/:id" element={<Page.UpdateWritingQuestion />} />
          </Route>
          <Route path="test-centers" element={<Page.TestCenter />}>
              <Route path="add-test-center" element={<Page.AddTestCenter />} />
              <Route path="manage-test-center" element={<Page.ManageTestCenters />} />
              <Route path="manage-test-center/:id" element={<Page.UpdateTestCenter />} />
              <Route path="test-center-licenses/:id" element={<Page.TestCenterLicenses />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
