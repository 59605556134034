import React, {useState, useEffect} from "react";
import { Grid, Button, Alert, Stack } from "@mui/material";
import {
  TextField,
  makeValidate,
  makeRequired
} from "mui-rff";
import EditIcon from "@mui/icons-material/Edit";
import * as Yup from "yup";
import { Form } from "react-final-form";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";
import { useNavigate, useParams } from "react-router-dom";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";


function UpdateTestCenter() {
    const params = useParams();
    const [message, setMessage] = useState();
    const [isDisabled, setIsDisabled] = useState(true);
    const [testCenter, setTestCenter] = useState(null)
    const navigate = useNavigate()
    const { isLoading, startLoading, stopLoading } = useLoadingContext();

    useEffect(() => {
        startLoading();

        Axios.get(
            DATABASE_URL + "/test_center/" + params.id,
            { headers: AuthHeader() }
        )
        .then((response) => {
            let t = response.data.testCenter[0]
            setTestCenter(t)
            stopLoading();
        })
        .catch((error) => {
            console.log(error.response);
            stopLoading();
        });
    }, [])

    const schema = Yup.object().shape({
        name: Yup.string().required("Test Center name is required"),
        address: Yup.string().required("Test Center address is required"),
        country: Yup.string().required("Country is required"),
        city: Yup.string().required("City is required"),
        officer_name: Yup.string().required("Officer Name is required"),
        number: Yup.string().required("Office Number is required"),
        email: Yup.string().required("Email is required"),
        webpage: Yup.string(),
        productive_skill_test_license: Yup.string().required("Productive Skill Test License"),
        receptive_skill_test_license: Yup.string().required("Receptive Skill Test License"),
        full_test_license: Yup.string().required("Full Test License"),
    });

    const validate = makeValidate(schema);

    const required = makeRequired(schema);

    const enableEdit = () => {
        setIsDisabled(false);
    };

    const testCenterFormFields = [
        <TextField
            label="Test Center Name"
            name="name"
            placeholder="Test Center Name"
            type="text"
            required={required.name}
            disabled={isDisabled}
        />,
        <TextField
            label="Address"
            name="address"
            placeholder="Adress"
            type="text"
            required={required.address}
            disabled={isDisabled}
        />,
        <TextField
            label="Country"
            name="country"
            placeholder="Country"
            type="text"
            required={required.country}
            disabled={isDisabled}
        />,
        <TextField
            label="City"
            name="city"
            placeholder="City"
            type="text"
            required={required.city}
            disabled={isDisabled}
        />,
        <TextField
            label="Officer Name"
            name="officer_name"
            placeholder="Officer Name"
            type="text"
            required={required.officer_name}
            disabled={isDisabled}
        />,
        <TextField
            label="Number"
            name="number"
            placeholder="Number"
            type="text"
            required={required.number}
            disabled={isDisabled}
        />,
        <TextField
            label="Email"
            name="email"
            placeholder="Email"
            type="email"
            required={required.email}
            disabled={isDisabled}
        />,
        <TextField
            label="Web Page"
            name="webpage"
            placeholder="Web Page"
            type="text"
            required={required.webpage}
            disabled={isDisabled}
        />,
        <TextField
            label="Productive Skill Test License"
            name="productive_skill_test_license"
            placeholder="Productive Skill Test License"
            type="number"
            required={required.productive_skill_test_license}
            disabled={isDisabled}
        />,
        <TextField
            label="Receptive Skill Test License"
            name="receptive_skill_test_license"
            placeholder="Receptive Skill Test License"
            type="number"
            required={required.receptive_skill_test_license}
            disabled={isDisabled}
        />,
        <TextField
            label="Full Test License"
            name="full_test_license"
            placeholder="Full Test License"
            type="number"
            required={required.full_test_license}
            disabled={isDisabled}
        />,
    ];

    const onSubmit = async (values) => {
        startLoading();
        setMessage("");

        Axios.put(
            DATABASE_URL + "/test_center/"+ params.id,
            { ...values },
            { headers: AuthHeader() }
        )
        .then((response) => {
            setMessage("Test Center Updated!");
            console.log(response.data);
            stopLoading();
            navigate("/test-centers/manage-test-center");
        })
        .catch((error) => {
            console.log(error.response);
            stopLoading();
        });
    };

    const onCancel = () => {
        navigate("/test-centers/manage-test-center");
    };
    
    return (
        <div>
            <div className="title-with-button">
                <h1>Update Test Center</h1>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<EditIcon />}
                    onClick={enableEdit}
                    disabled={!isDisabled}
                >
                    Enable Edit
                </Button>
            </div>
            <Stack spacing={2}>
                {isLoading ? (
                    <div className="loader-display">
                        <Loader />
                    </div>
                ) : (
                    <>
                        {message && <Alert severity="success">{message}</Alert>}
                        <Form
                            onSubmit={onSubmit}
                            validate={validate}
                            initialValues={testCenter}
                            render={({
                                hasSubmitErrors,
                                handleSubmit,
                                submitting,
                                form,
                                values,
                            }) => (
                                <form onSubmit={handleSubmit} className="add-user-form">
                                    <Grid container direction="row" spacing={4}>
                                        {testCenterFormFields.map((field, index) => (
                                        <Grid item xs={6} key={index}>
                                            {field}
                                        </Grid>
                                        ))}
                                    </Grid>
                                    <Grid container spacing={4} className="form-button-group">
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                size="large"
                                                disabled={submitting || isDisabled}
                                            >
                                                Update
                                            </Button>
                                            </Grid>
                                            <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                type="submit"
                                                size="large"
                                                disabled={submitting}
                                                onClick={onCancel}
                                            >
                                                BACK
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {hasSubmitErrors && <Alert severity="error">error</Alert>}
                                    {/* <pre>{JSON.stringify(values)}</pre> */}
                                </form>
                            )}
                        />
                    </>
                )}
            </Stack>
        </div>
    )
}


export default UpdateTestCenter
