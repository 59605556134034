import React, { useEffect } from "react";
import { TextField, makeValidate, makeRequired } from "mui-rff";
import * as Yup from "yup";
import { Grid, Button, Alert } from "@mui/material";
import { Form } from "react-final-form";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";
import { useState } from "react";
import AuthHeader from "../../services/AuthHeader";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

import "./Profile.css";

export default function TestCenterDetails() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [testCenterDetails, setTestCenterDetails] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const { isLoading, startLoading, stopLoading } = useLoadingContext();

  const schema = Yup.object().shape({
    name: Yup.string().required("Test Center Name is required"),
    officer_name: Yup.string().required("Officer Name is required"),
    address: Yup.string().required("Address is required"),
    number: Yup.string()
      .matches(/^[0-9-()]*$/, "Please enter a valid phone number")
      .required("Contact number is required"),
    country: Yup.string(),
    email: Yup.string().email().required("Email is required"),
    webpage: Yup.string().matches(
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
      "Please enter vaild url"
    ),
  });

  const validate = makeValidate(schema);

  const required = makeRequired(schema);

  const formFields = [
    <TextField
      label="Test Center Name"
      name="name"
      placeholder="Test Center Name"
      type="text"
      disabled={isDisabled}
      required={required.name}
    />,
    <TextField
      label="Exam Officer Name"
      name="officer_name"
      placeholder="Exam Officer Name"
      type="text"
      disabled={isDisabled}
      required={required.officer_name}
    />,
    <TextField
      label="Address"
      name="address"
      placeholder="Address"
      type="text"
      disabled={isDisabled}
      required={required.address}
    />,
    <TextField
      label="Contact Number"
      name="number"
      placeholder="Contact Number"
      type="text"
      disabled={isDisabled}
      required={required.number}
    />,
    <TextField
      label="Country"
      name="country"
      placeholder="Country"
      type="text"
      disabled={isDisabled}
      required={required.country}
    />,
    <TextField
      label="Contact Email"
      name="email"
      placeholder="Contact Email"
      type="email"
      disabled={isDisabled}
      required={required.email}
    />,
    <TextField
      label="City"
      name="city"
      placeholder="City"
      type="text"
      disabled={isDisabled}
    />,
    <TextField
      label="Test Center Webpage"
      name="webpage"
      placeholder="Test Center Webpage"
      type="text"
      disabled={isDisabled}
    />,
  ];

  useEffect(() => {
    startLoading();

    Axios.get(DATABASE_URL + "/test_center/" + user.test_center, {
      headers: AuthHeader(),
    }).then((response) => {
      const details = response.data.testCenter;
      setTestCenterDetails(...details);
      stopLoading();
    }).catch((error) => {
      console.log(error);
      stopLoading();
    });
  }, []);

  const onSubmit = async (values) => {
    startLoading();

    Axios.put(
      DATABASE_URL + "/test_center/" + user.test_center,
      { ...values },
      { headers: AuthHeader() }
    )
      .then((response) => {
        console.log("Test Center Updated");
        stopLoading();
        navigate("/profile");
      })
      .catch((error) => {
        console.log(error.response.data.message);
        stopLoading();
      });
  };

  const onCancel = () => {
    navigate("/profile");
  };
  const enableEdit = () => {
    setIsDisabled(false);
  };

  return (
    <div>
      <div className="title-with-button">
        <h1>Test Center Details</h1>
        <Button
          variant="outlined"
          size="large"
          startIcon={<EditIcon />}
          onClick={enableEdit}
        >
          Enable Edit
        </Button>
      </div>
      {isLoading ? (
        <div className="loader-display">
          <Loader />
        </div>
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={testCenterDetails}
          validate={validate}
          render={({
            hasSubmitErrors,
            handleSubmit,
            submitting,
            form,
            values,
          }) => (
            <form onSubmit={handleSubmit} className="test-center-form">
              <Grid container direction="row" spacing={4}>
                {formFields.map((field, index) => (
                  <Grid item xs={6} key={index}>
                    {field}
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={4} className="form-button-group" justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={submitting || isDisabled}
                  >
                    UPDATE
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={submitting}
                    onClick={onCancel}
                  >
                    CANCEL
                  </Button>
                </Grid>
              </Grid>
              {hasSubmitErrors && <Alert severity="error">error</Alert>}
              {/* <pre>{JSON.stringify(values)}</pre> */}
            </form>
          )}
        />
      )}
    </div>
  );
}
