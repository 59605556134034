import React from "react";
import { SecondaryNavbar } from "../../components/common";
import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import * as FaIcons from "react-icons/fa"

import { USER_ROLES } from "../../appConstants";
import { useAuthContext } from "../../hooks/useAuthContext";

const testCenterPage = (
  <NavLink to="test-center-details"> <FaIcons.FaClipboard className="fa-i" /> Test Center Details</NavLink>
);
const userPage = <NavLink to="user-details"> <FaIcons.FaClipboardList className="fa-i" /> My Details</NavLink>;

const subMenu = [];
subMenu[USER_ROLES.Administrator] = [testCenterPage, userPage];
subMenu[USER_ROLES.Proctor] = [userPage];
subMenu[USER_ROLES.Examiner] = [userPage];

function Profile() {
  const { user } = useAuthContext();

  return (
    <div className="page-content-container">
      <SecondaryNavbar menu={subMenu[user.role]} />
      <div className="page-content">
        <Outlet />
      </div>
    </div>
  );
}

export default Profile;
