import React, { useState } from "react";
import { Form } from "react-final-form";
import * as Yup from "yup";
import { TextField, makeValidate, makeRequired, Select } from "mui-rff";
import { Grid, Button, Alert, MenuItem, Modal, Box } from "@mui/material";
import { MARKS } from "../../appConstants";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";
import { useParams, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { formatInTimezone } from "../../utils/formatInTimezone";
import { Loader } from "../../components/common";

function GradingSpeaking({ candidate_id, questionDetails, answer, isLoading, startLoading, stopLoading }) {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [open, setOpen] = useState(false);

  const schema = Yup.object().shape({
    range: Yup.number().min(0).max(20).required("Please select score"),
    accuracy: Yup.number().min(0).max(20).required("Please select score"),
    fluency: Yup.number().min(0).max(20).required("Please select score"),
    coherence: Yup.number().min(0).max(20).required("Please select score"),
    phonology: Yup.number().min(0).max(20).required("Please select score"),
  });

  const validate = makeValidate(schema);

  const required = makeRequired(schema);

  const formFields = [
    <Select
      label="Range"
      name="range"
      placeholder="Range"
      required={required.range}
      MenuProps={{PaperProps: {sx: {maxHeight: 200}}}}
    >
      {MARKS.map((o, idx) => (
        <MenuItem value={o} key={idx}>
          {o}
        </MenuItem>
      ))}
    </Select>,
    <Select
      label="Accuracy"
      name="accuracy"
      placeholder="Accuracy"
      required={required.accuracy}
      MenuProps={{PaperProps: {sx: {maxHeight: 200}}}}
    >
      {MARKS.map((o, idx) => (
        <MenuItem value={o} key={idx}>
          {o}
        </MenuItem>
      ))}
    </Select>,
    <Select
      label="Fluency"
      name="fluency"
      placeholder="Fluency"
      required={required.fluency}
      MenuProps={{PaperProps: {sx: {maxHeight: 200}}}}
    >
      {MARKS.map((o, idx) => (
        <MenuItem value={o} key={idx}>
          {o}
        </MenuItem>
      ))}
    </Select>,
    <Select
      label="Coherence"
      name="coherence"
      placeholder="Coherence"
      required={required.coherence}
      MenuProps={{PaperProps: {sx: {maxHeight: 200}}}}
    >
      {MARKS.map((o, idx) => (
        <MenuItem value={o} key={idx}>
          {o}
        </MenuItem>
      ))}
    </Select>,
    <Select
      label="Phonology"
      name="phonology"
      placeholder="Phonology"
      required={required.phonology}
      MenuProps={{PaperProps: {sx: {maxHeight: 200}}}}
    >
      {MARKS.map((o, idx) => (
        <MenuItem value={o} key={idx}>
          {o}
        </MenuItem>
      ))}
    </Select>,
  ];

  function sum(obj) {
    return Object.keys(obj).reduce(
      (sum, key) => sum + parseFloat(obj[key] || 0),
      0
    );
  }

  const onSubmit = async (values) => {
    startLoading();

    const comments = values.comments;
    delete values.comments;
    const grade = sum(values);
    Axios.put(
      DATABASE_URL + "/grading/" + params.id,
      {
        candidate_id: candidate_id,
        examiner_id: user.userId,
        grade: grade,
        grade_breakdown: values,
        grade_time: formatInTimezone(),
        comments: comments,
      },
      {
        headers: AuthHeader(),
      }
    ).then((response) => {
      stopLoading();
      navigate("/grading/pending-for-grading");
    }).catch((error) => {
      console.log('Error: ', error);
      stopLoading();
    });
  };

  return (
    <>
      <div className="grading-speaking-wrapper">
        {isLoading ? (
          <div className="loader-display">
            <Loader />
          </div>
        ) : (
          <>
            <audio src={answer} controls="controls" />
            <div className="details">
              <p>{questionDetails && questionDetails.name}</p>
              <p>{questionDetails && questionDetails.description}</p>
            </div>
          </>
        )}
      </div>
      {user.role === 3 &&
        <div className="grading-marks-wrapper">
          {isLoading ? (
            <div className="loader-display">
              <Loader />
            </div>
          ) : (
            <Form
              onSubmit={onSubmit}
              validate={validate}
              render={({
                hasSubmitErrors,
                handleSubmit,
                submitting,
                form,
                values,
              }) => (
                <form onSubmit={handleSubmit} className="add-user-form">
                  <p>Marks</p>
                  <Grid container direction="row" spacing={4}>
                    {formFields.map((field, index) => (
                      <Grid item xs={2} key={index}>
                        {field}
                      </Grid>
                    ))}
                    <Grid className="final-grade">
                      <label>Final Grade</label>
                      <p>
                        {values.range != null &&
                        values.accuracy != null &&
                        values.fluency != null &&
                        values.coherence != null &&
                        values.phonology != null
                          ? values.range +
                            values.accuracy +
                            values.fluency +
                            values.coherence +
                            values.phonology
                          : ""}
                      </p>
                    </Grid>
                  </Grid>
                  <p>Comments</p>
                  <Grid container direction="row" spacing={4}>
                    <Grid item xs={12}>
                      <TextField multiline name="comments" rows={4} required />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} className="form-button-group">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        disabled={submitting}
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        disabled={submitting}
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        Grading Criteria
                      </Button>
                      <Modal
                        open={open}
                        onClose={() => {
                          setOpen(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box className="modal-wrapper">
                          <img
                            src="/images/Rubric_for_Speaking.png"
                            alt="Rubric_for_Speaking"
                          />
                        </Box>
                      </Modal>
                    </Grid>
                  </Grid>
                  {hasSubmitErrors && <Alert severity="error">error</Alert>}
                  {/* <pre>{JSON.stringify(values)}</pre> */}
                </form>
              )}
            />
          )}
        </div>
      
      }
    </>
  );
}

export default GradingSpeaking;
