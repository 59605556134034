// export const DATABASE_URL = "http://localhost:3000";
export const DATABASE_URL = window.location.hostname.indexOf("-stage") > -1 
? "https://certification-api-stage.elltechnologies.com" 
:  window.location.hostname.indexOf("-prod") > -1 ? "https://certification-api-prod.elltechnologies.com"  : "https://certification-api.elltechnologies.com";

export const USER_ROLES = {
  Administrator: 1,
  Proctor: 2,
  Examiner: 3,
  Superuser: 4
};

export const ROLE = [];
ROLE[1] = "Administrator";
ROLE[2] = "Proctor";
ROLE[3] = "Examiner";
ROLE[4] = "Superuser";

export const ADMINISTRATOR_ROLE = [];
ADMINISTRATOR_ROLE[2] = "Proctor";
ADMINISTRATOR_ROLE[3] = "Examiner";

export const GRADING_ROLE = ["speaking", "writing", "both"];


export const SUPERUSER_ROLE = [];
SUPERUSER_ROLE[1] = "Administrator";
SUPERUSER_ROLE[4] = "Superuser";


export const STATUS = ["Suspended", "Active"];
export const COMPLETED_TEST = ["No", "Yes"];

export const TYPE_OF_TEST = [];
TYPE_OF_TEST[1] = "Full Test";
TYPE_OF_TEST[2] = "Productive Skills Test";
TYPE_OF_TEST[3] = "Receptive Skills Test";
TYPE_OF_TEST[4] = "Demo Test";

export const MARKS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
