import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import Axios from "axios";

import { DATABASE_URL } from "../../../appConstants";
import { useAuthContext } from "../../../hooks/useAuthContext";

import "./Header.css";

function Header() {
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    Axios.get(DATABASE_URL + "/logout");
    localStorage.removeItem("currentUser");
    navigate("/login");
  };

  return (
    <header className="header">
      <div className="header-logo">
        <a href="/">
          <img src="/images/ell-logo.png" alt="logo" />
        </a>
      </div>
      <h1>ELL Assessment Test Portal</h1>
      <div className="header-login">
        <Button onClick={logout} size='large' color='error'>
          <FaIcons.FaSignOutAlt />
        </Button>
      </div>
    </header>
  );
}

export default Header;
