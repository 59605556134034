import React from "react";

import "./Home.css";

function Home() {
  return (
    <div className="home-container">
      <img src="/images/ell-logo-only.png" alt="logo" />
      <h2>Welcome to ELL Assessment Test Portal</h2>
      <p>
        <strong>
          You can access all the necessary tools to administer the ELL
          Assessment Test.{" "}
        </strong>
      </p>
      <p>
        For more information, please download the <a href="/">Center Manual</a>.
      </p>
    </div>
  );
}

export default Home;
