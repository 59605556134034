import React from "react";
import { Grid } from "@mui/material";

function Forms() {
  return (
    <>
      <h1>Forms</h1>
      <h5>Choose the form you wish to download:</h5>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <div className="download-name-wrapper">
            <a
              target="_blank"
              href="/forms/Formato_de_Registro_de_incidentes.docx"
            >
              Formato de Registro de incidentes
            </a>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="download-name-wrapper">
            <a
              target="_blank"
              href="/forms/Comportamiento_Fraudulento_Centro_de_Pruebas.docx"
            >
              Comportamiento Fraudulento Centro de Pruebas
            </a>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="download-name-wrapper">
            <a
              target="_blank"
              href="/forms/Comportamiento_Fraudulento_Candidato.docx"
            >
              Comportamiento Fraudulento Candidato
            </a>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="download-name-wrapper">
            <a target="_blank" href="/forms/Plano_de_Asientos.docx">
              Plano de Asientos
            </a>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="download-name-wrapper">
            <a
              target="_blank"
              href="/forms/Comentarios_Sobre_el_ELL_Assessment_Test.docx"
            >
              Comentarios Sobre el ELL Assessment Test
            </a>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="download-name-wrapper">
            <a target="_blank" href="/forms/Entrenamiento_a_Vigilantes.docx">
              Entrenamiento a Vigilantes
            </a>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="download-name-wrapper">
            <a target="_blank" href="/forms/Entrenamiento_a_Vigilantes.docx">
              Entrenamiento a Vigilantes
            </a>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="download-name-wrapper">
            <a target="_blank" href="/forms/Protocolos_de_Chequeo.docx">
              Protocolos de Chequeo
            </a>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Forms;
