import React from "react";
import { SecondaryNavbar } from "../../components/common";
import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import * as FaIcons from 'react-icons/fa'

const subMenu = [
    <NavLink to="add-test-center"> <FaIcons.FaPlusCircle className="m-r" />  Add Test Center</NavLink>,
    <NavLink to="manage-test-center"> <FaIcons.FaMicrophoneAlt className="m-r" />  Manage Test Centers</NavLink>,
];

function TestCenter () {
    return (
        <div className="page-content-container">
          <SecondaryNavbar menu={subMenu} />
          <div className="page-content">
            <Outlet />
          </div>
        </div>
    );
}

export default TestCenter