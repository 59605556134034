import React, { useState, useEffect, useMemo } from "react";
import Axios from "axios";
import moment from "moment-timezone";
import { DATABASE_URL } from "../../appConstants";
import { useParams } from "react-router-dom";
import AuthHeader from "../../services/AuthHeader";
import { TYPE_OF_TEST } from "../../appConstants";
import { useCEFRconverter } from "../../hooks/useCEFRconverter";
import { Button } from "@mui/material";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

import "./Certificate.css";

function Certificate() {
  console.log('Certificate component rendered');
  const params = useParams();
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [testCenterDetails, setTestCenterDetails] = useState(null);
  const [gradeDetails, setGradeDetails] = useState(null);
  const { isLoading, startLoading, stopLoading } = useLoadingContext();

  useEffect(() => {
    console.log('Fetching candidate data, params.id:', params.id);
    startLoading();

    Axios.get(DATABASE_URL + "/candidate/" + params.id, {
      headers: AuthHeader(),
    }).then((response) => {
      const candidate = response.data.candidate[0];
      setCandidateDetails(candidate);
      stopLoading();
    }).catch((error) => {
      console.log('Error fetching candidate data:', error);
      stopLoading();
    });
  }, [params.id]);

  useEffect(() => {
    if (candidateDetails) {
      console.log('Fetching test center and grading data');
      startLoading();

      Axios.get(
        DATABASE_URL + "/test_center/" + candidateDetails.test_center_id,
        {
          headers: AuthHeader(),
        }
      ).then((response) => {
        const testCenter = response.data.testCenter[0];
        setTestCenterDetails(testCenter);
        stopLoading();
      }).catch((error) => {
        console.log('Error fetching test center data:', error);
        stopLoading();
      });

      Axios.get(DATABASE_URL + "/grading/candidate/" + candidateDetails.id, {
        headers: AuthHeader(),
      }).then((response) => {
        const grading = response.data;
        setGradeDetails(grading);
        stopLoading();
      }).catch((error) => {
        console.log('Error fetching grading data:', error);
        stopLoading();
      });
    }
  }, [candidateDetails]);

  const formatDate = (date, timezone) => {
    if (!date || !timezone) {
      return '';
    }

    const parsedDate = moment.utc(date).tz(timezone);

    if (!parsedDate.isValid()) {
      console.error('Invalid date');
      return '';
    }

    const dayOfMonth = parsedDate.date();
    const monthName = parsedDate.format('MMM');
    const yearString = parsedDate.year();
    let ordinal = 'th';

    if (dayOfMonth > 3 && dayOfMonth < 21) {
      ordinal = 'th';
    } else {
      if (dayOfMonth % 10 === 1) ordinal = 'st';
      if (dayOfMonth % 10 === 2) ordinal = 'nd';
      if (dayOfMonth % 10 === 3) ordinal = 'rd';
    }

    return (
      <>
        {monthName} {dayOfMonth}
        <sup>{ordinal}</sup> {yearString}
      </>
    );
  };

  const formatDateOfBirth = (date) => {
    if (!date) {
      return '';
    }

    const parsedDate = moment.utc(date);

    if (!parsedDate.isValid()) {
      console.error('Invalid date of birth');
      return '';
    }

    const dayOfMonth = parsedDate.date();
    const monthName = parsedDate.format('MMM');
    const yearString = parsedDate.year();
    let ordinal = 'th';

    if (dayOfMonth > 3 && dayOfMonth < 21) {
      ordinal = 'th';
    } else {
      if (dayOfMonth % 10 === 1) ordinal = 'st';
      if (dayOfMonth % 10 === 2) ordinal = 'nd';
      if (dayOfMonth % 10 === 3) ordinal = 'rd';
    }

    return `${monthName} ${dayOfMonth}${ordinal} ${yearString}`;
  };

  const formattedDateOfBirth = useMemo(() => {
    if (candidateDetails && candidateDetails.date_of_birth) {
      return formatDateOfBirth(candidateDetails.date_of_birth);
    }
    return '';
  }, [candidateDetails]);

  const downloadCertificate = () => {
    if (candidateDetails) {
      const doc = document.getElementById('certificate-container');
      html2canvas(doc, {
        scale: 2,
        width: doc.scrollWidth,
        height: doc.scrollHeight,
        useCORS: true, // If you have external images, ensure they are CORS enabled
        allowTaint: true,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('landscape', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${candidateDetails.first_name}-${candidateDetails.last_name}-CERTIFICATE-OF-ACHIEVEMENT.pdf`);
      }).catch((error) => {
        console.error('Error generating PDF:', error);
      });
    } else {
      console.log('Cannot download certificate: candidateDetails is null');
    }
  };

  if (isLoading || !candidateDetails) {
    return (
      <div className="loader-display">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="download-btn-container">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          onClick={downloadCertificate}
          disabled={isLoading}
        >
          Download Certificate
        </Button>
      </div>
      <div className="certificate-wrapper" id="certificate-container">
        <div className="page first-page">
          <div className="title">
            <h3>CERTIFICATE</h3>
            <p>OF ACHIEVEMENT</p>
          </div>
          <div className="content">
            <p>This is to certify that</p>
            <h3>
              {candidateDetails.last_name}, {candidateDetails.first_name}
            </h3>
            <p>
              Has achieved an overall score of{" "}
              <strong>{candidateDetails.grade ? candidateDetails.grade : 0}</strong> on the ELL Assessment
              Test which corresponds to a{" "}
              <strong>({useCEFRconverter(Number(candidateDetails.grade))})</strong> in
              the Common European Framework of Reference.
            </p>
            <p>{formatDate(moment(candidateDetails.test_date).format(), candidateDetails.time_zone)}</p>
          </div>
          <div className="logo">
            <img src="/images/square_logo.png" alt="logo" />
          </div>
          <div className="verification-code">{candidateDetails.id}</div>
          <div className="sign first-sign">
            <img src="/images/rebecca_sign.png" alt="Rebecca Verbeem's signature" />
            <p>
              Rebecca Verbeem
              <br />
              Operations Manager, Everybody Loves Languages
            </p>
          </div>
          <div className="sign second-sign">
            <img src="/images/gail_sign.png" alt="Gali Bar-Ziv's signature" />
            <p>
              Gali Bar-Ziv
              <br />
              CEO, Everybody Loves Languages
            </p>
          </div>
        </div>
        <div className="page second-page">
          <div className="title-image">
            <h3>
              OFFICIAL
              <br />
              SCORE
              <br />
              REPORT
            </h3>
            <img src={candidateDetails.image_string} alt="Candidate's image" />
            <p>{candidateDetails.id}</p>
          </div>
          <div className="result">
            <img src="/images/ell-logo.png" alt="ELL logo" />
            <div className="candidate-info">
              <p>Last Name: {candidateDetails.last_name}</p>
              <p>First Name: {candidateDetails.first_name}</p>
              <p>Date of Birth: {formattedDateOfBirth}</p>
              <p>Government ID: {candidateDetails.government_id}</p>
              <p>Test Date: {formatDate(moment(candidateDetails.test_date).format(), candidateDetails.time_zone)}</p>
              <p>Test Type: {TYPE_OF_TEST[candidateDetails.type_of_test_id]}</p>
            </div>
            <div className="score">
            <div className="left">
            <p>
              <strong>OVERALL SCORE:</strong> {candidateDetails.grade} ({useCEFRconverter(Number(candidateDetails.grade))})
            </p>
            <p>
              <strong>
                1<sup>st</sup> SECTION - LISTENING AND READING:
              </strong>
              {gradeDetails && `${gradeDetails.listeningAndReadingGrade} (${useCEFRconverter(gradeDetails.listeningAndReadingGrade)})`}
            </p>
            <p>
              <strong>
                2<sup>nd</sup> SECTION - SPEAKING:
              </strong>
              {gradeDetails && `${gradeDetails.speakingGrade} (${useCEFRconverter(gradeDetails.speakingGrade)})`}
            </p>
            <p>
              <strong>
                2<sup>nd</sup> SECTION - WRITING:
              </strong>
              {gradeDetails && `${gradeDetails.writingGrade} (${useCEFRconverter(gradeDetails.writingGrade)})`}
            </p>
          </div>
              <div className="right">
                <p>Test Center: </p>
                <p>{testCenterDetails && testCenterDetails.name}</p>
                <p>Test Center ID: </p>
                <p>{testCenterDetails && testCenterDetails.id}</p>
                <p>Location:</p>
                <p>{testCenterDetails && testCenterDetails.address}</p>
                <p>Issued on: </p>
                <p>{formatDate(new Date(), candidateDetails.time_zone)}</p>
              </div>
            </div>
          </div>
          <div className="sign first-sign">
            <img src="/images/rebecca_sign.png" alt="Rebecca Verbeem's signature" />
            <p>
              Rebecca Verbeem
              <br />
              Operations Manager, Everybody Loves Languages
            </p>
          </div>
          <div className="sign second-sign">
            <img src="/images/gail_sign.png" alt="Gali Bar-Ziv's signature" />
            <p>
              Gali Bar-Ziv
              <br />
              CEO, Everybody Loves Languages
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Certificate;
